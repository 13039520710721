// NoPermission.js
import React from "react";

const NoPermission = () => {
  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>Access Denied</h1>
      <p>You do not have permission to view this page.</p>
    </div>
  );
};

export default NoPermission;
