import React, { Component, Fragment } from "react";
import Popup from "reactjs-popup";
import {
  FormControl,
  ControlLabel,
  Row,
  Col,
  FormGroup,
  Radio,
} from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import { selectedFilesSendEmail } from "../libs/API";

class EmailPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      emailInputField: "",
      bcc: "",
      cc: "",
      selectedDay: "",
      isLoading: false,
      isChecked: "original",
      hasRedaction: false,
      pageFrom: -1,
      pageTo: -1,
    };
    // console.log("email popup props", props);
  }

  async componentDidMount() {
    this.setState({ popupOpen: this.props.props.showEmailPopup });
    await this.checkRedaction();
  }

  checkRedaction = async () => {
    let fileArray = this.props.props.fileIdArray;
    let redaction = fileArray.some((x) => x.redaction === true);
    this.setState({ hasRedaction: redaction });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
    this.props.unmountMe();
  };

  handleEmailSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let emailParams = {
      fileData: this.props.props.fileIdArray,
      hasManageRedaction: this.props.props.manageRedaction,
      selectedOption: this.state.isChecked,
      emailTo: this.state.emailInputField,
      ccs: this.state.cc,
      bccs: this.state.bcc,
      selectedDay: this.state.selectedDay,
      pageFrom: this.state.pageFrom,
      pageTo: this.state.pageTo,
    };
    selectedFilesSendEmail(emailParams).then((data) => {
      this.setState({ isLoading: false });
      if (data.status === 200) {
        this.closePopup();
      }
    });
  };

  handleRadioChange = (e) => {
    const value = e.target.value;
    this.setState({ isChecked: value });
  };

  render() {
    const rowStyle = {
      marginBottom: "0.4rem",
    };
    return (
      <Fragment>
        <Popup
          contentStyle={{
            width: "32%",
            borderRadius: "15px",
            padding: "15px",
          }}
          open={this.state.popupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closePopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            <div>
              <h3>Send Email</h3>
              <form onSubmit={this.handleEmailSubmit}>
                <FormGroup>
                  <Row style={rowStyle}>
                    <Col sm={12}>
                      <Radio
                        value="original"
                        name="emailOriginal"
                        onChange={this.handleRadioChange}
                        checked={this.state.isChecked === "original"}
                      >
                        {""} Email Original Images
                      </Radio>
                    </Col>
                  </Row>
                  {/* <Row style={rowStyle}>
                    <Col sm={12}>
                      <Radio
                        value="originalRange"
                        name="emailOriginalRange"
                        onChange={this.handleRadioChange}
                        checked={this.state.isChecked === "originalRange"}
                      >
                        {""} Email Range of Original Images
                      </Radio>
                    </Col>
                  </Row> */}
                  {/* {this.state.isChecked === "originalRange" && (
                    <Row style={rowStyle}>
                      <Col sm={3}>
                        <FormControl
                          componentClass="input"
                          onChange={(e) =>
                            this.setState({ pageFrom: e.target.value })
                          }
                          type="number"
                          placeholder="page from"
                          autoComplete="off"
                        ></FormControl>
                      </Col>
                      <Col sm={3}>
                        <FormControl
                          componentClass="input"
                          onChange={(e) =>
                            this.setState({ pageTo: e.target.value })
                          }
                          type="number"
                          placeholder="page to"
                          autoComplete="off"
                        ></FormControl>
                      </Col>
                      <Col sm={6}></Col>
                    </Row>
                  )} */}
                  {this.props.props.manageRedaction && this.state.hasRedaction && (
                    <div>
                      <Row style={rowStyle}>
                        <Col sm={12}>
                          <Radio
                            value="redacted"
                            name="emailRedacted"
                            onChange={this.handleRadioChange}
                            checked={this.state.isChecked === "redacted"}
                          >
                            {""} Email Redacted Document
                          </Radio>
                        </Col>
                      </Row>
                      {/* <Row style={rowStyle}>
                        <Col sm={12}>
                          <Radio
                            value="redactedRange"
                            name="emailRedactedRange"
                            onChange={this.handleRadioChange}
                            checked={this.state.isChecked === "redactedRange"}
                          >
                            {""} Email Range of Redacted Document
                          </Radio>
                        </Col>
                      </Row> */}
                    </div>
                  )}
                  {/* {this.state.isChecked === "redactedRange" && (
                    <Row style={rowStyle}>
                      <Col sm={3}>
                        <FormControl
                          componentClass="input"
                          onChange={(e) =>
                            this.setState({ pageFrom: e.target.value })
                          }
                          type="number"
                          placeholder="page from"
                          autoComplete="off"
                        ></FormControl>
                      </Col>
                      <Col sm={3}>
                        <FormControl
                          componentClass="input"
                          onChange={(e) =>
                            this.setState({ pageTo: e.target.value })
                          }
                          type="number"
                          placeholder="page to"
                          autoComplete="off"
                        ></FormControl>
                      </Col>
                      <Col sm={6}></Col>
                    </Row>
                  )} */}
                  <Row style={rowStyle}>
                    <Col sm={4} componentClass={ControlLabel}>
                      Email To:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        required
                        onChange={(e) =>
                          this.setState({ emailInputField: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={rowStyle}>
                    <Col sm={4} componentClass={ControlLabel}>
                      Cc:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) => this.setState({ cc: e.target.value })}
                      />
                    </Col>
                  </Row>
                  <Row style={rowStyle}>
                    <Col sm={4} componentClass={ControlLabel}>
                      Bcc:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) => this.setState({ bcc: e.target.value })}
                      />
                    </Col>
                  </Row>
                  <Row style={rowStyle}>
                    <Col sm={6} componentClass={ControlLabel}>
                      Day(s) for Link Expiration:
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        componentClass="select"
                        onChange={(e) =>
                          this.setState({ selectedDay: e.target.value })
                        }
                        required
                      >
                        <option key={-1} value="">
                          Select Day(s)
                        </option>
                        <option key={0} value="1">
                          1
                        </option>
                        <option key={1} value="5">
                          5
                        </option>
                        <option key={2} value="7">
                          7
                        </option>
                        <option key={3} value="10">
                          10
                        </option>
                        <option key={4} value="15">
                          15
                        </option>
                        <option key={5} value="30">
                          30
                        </option>
                      </FormControl>
                    </Col>
                  </Row>
                  <LoaderButton
                    block
                    bsClass="btn btn-outline-secondary"
                    disabled={this.state.isLoading}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Send Email"
                    loadingText="Sending Email......"
                  />
                </FormGroup>
              </form>
            </div>
          </div>
        </Popup>
      </Fragment>
    );
  }
}

export default EmailPopup;
