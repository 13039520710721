import React, { Component, Fragment } from "react";
import "../Browse/browse.css";
import { ReactTabulator } from "react-tabulator";
import Popup from "reactjs-popup";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import config from "../../config";
import { Auth } from "aws-amplify";
// import TifImage from "../Images/tif.gif";
// import PdfImage from "../Images/pdf.gif";

class BrowseGridView extends Component {
  gridBrowseRef = null;
  constructor(props) {
    super(props);
    this.state = {
      viewerOpen: false,
    };
    //console.log("anchor", props);
  }

  async componentDidMount() {
    this.props.onRef(this);
  }

  openViewer = () => {
    this.setState({ viewerOpen: true });
  };

  closeViewer = () => {
    this.setState({ viewerOpen: false });
  };

  viewFile = async (e, cell) => {
    e.preventDefault();
    this.openViewer();
    const fileId = cell.getRow().getData().FileId;
    const fileName = cell.getRow().getData().FileName;
    const targetOrigin = config.ImageViewer;
    const refreshToken = (await Auth.currentAuthenticatedUser())
      .signInUserSession.refreshToken.token;
    const message = {
      refreshToken: refreshToken,
      fileId: fileId,
      editIndex: this.props.editIndex,
      manageRedaction: this.props.manageRedaction,
      deletePages: this.props.deletePages,
      fileName: fileName,
      userEmailId: this.props.loggedInUser,
      managePii: this.props.hasPIIRole,
      clientId: this.props.clientId,
    };
    const imageViewer = document.getElementById("imageViewer");
    if (imageViewer.attachEvent) {
      imageViewer.attachEvent("onload", function () {
        imageViewer.contentWindow.postMessage(
          JSON.stringify(message),
          targetOrigin
        );
      });
    } else {
      imageViewer.onload = function () {
        imageViewer.contentWindow.postMessage(
          JSON.stringify(message),
          targetOrigin
        );
      };
    }
  };

  handleFilePath = (renameFile) => {
    let canBeLink = function (cell) {
      return cell.getRow().getData().FileId === 0 || renameFile != null
        ? cell.getValue()
        : "<a href=" + cell.getRow().getData() + ">" + cell.getValue() + "</a>";
    };

    let boolFormatter = function (cell) {
      return cell.getValue() === false ? "No" : "Yes";
    };

    let dateFormatter = function (cell) {
      var value = cell.getValue();
      let dateString = cell.getRow().getData().Date;
      if (value && dateString) {
        if (value === dateString) {
          let date = new Date(value);
          value = getFormattedDate(date);
        }
      }
      return value;
    };

    function getFormattedDate(date) {
      const year = date.getUTCFullYear();
      let month = (date.getUTCMonth() + 1).toString();
      month = month.length > 1 ? month : "0" + month;
      let day = date.getUTCDate().toString();
      day = day.length > 1 ? day : "0" + day;
      return month + "/" + day + "/" + year;
    }
    return []
      .concat({
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        titleFormatterParams: {
          rowRange: "visible",
        },
        // hozAlign: "center",
        width: "1px",
        headerSort: false,
        visible: !renameFile ? true : false,
        cellClick: function (e, cell) {
          cell.getRow().toggleSelect();
        },
      })
      .concat({
        title: "File Name",
        field: "FileName",
        formatter: canBeLink,
        editor: "input",
        cellClick: (e, cell) => {
          if (!renameFile && cell.getRow().getData().FileId !== 0) {
            this.viewFile(e, cell);
          }
        },
      })
      .concat(
        this.props.columns.map((c, i) => {
          let col = {
            title: c.InputName,
            field: c.InputName,
            formatter: dateFormatter,
            visible: !this.props.templateColumnsToShow.includes(i.toString()),
          };
          return col;
        })
      )
      .concat({
        title: "Redaction",
        field: "HasAnnotations",
        formatter: boolFormatter,
        cellClick: (e, cell) => {
          if (!renameFile) {
            this.viewFile(e, cell);
          }
        },
        visible: this.props.manageRedaction && this.props.redactionColumn,
      })
      .concat({
        title: "Page Count",
        field: "PageCount",
        cellClick: (e, cell) => {
          if (!renameFile) {
            this.viewFile(e, cell);
          }
        },
        visible: this.props.fileProperties && this.props.pageCount,
      })
      .concat({
        title: "File Size (Bytes)",
        field: "FileSize",
        formatter: function (cell, formatterParams) {
          if (cell.getValue() != null) {
            return cell.getValue().toLocaleString();
          } else {
            return cell.getValue();
          }
        },
        cellClick: (e, cell) => {
          if (!renameFile) {
            this.viewFile(e, cell);
          }
        },
        visible: this.props.fileProperties && this.props.fileSize,
      })
      .concat({
        title: "Page Dimensions",
        field: "PageDimensions",
        cellClick: (e, cell) => {
          if (!renameFile) {
            this.viewFile(e, cell);
          }
        },
        visible: this.props.fileProperties && this.props.pageDimensions,
      });
  };

  handleIndexTemplateColumns = (renameFile) => {
    let canBeLink = function (cell) {
      return cell.getRow().getData().FileId === 0 || renameFile != null
        ? cell.getValue()
        : "<a href=" + cell.getRow().getData() + ">" + cell.getValue() + "</a>";
    };

    let boolFormatter = function (cell) {
      return cell.getValue() === false ? "No" : "Yes";
    };
    let newColumns = [
      {
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        headerSort: false,
        width: "1px",
        titleFormatterParams: {
          rowRange: "visible",
        },
        cellClick: (e, cell) => {
          cell.getRow().toggleSelect();
        },
        visible: !renameFile ? true : false,
      },
      {
        title: "File Name",
        field: "FileName",
        formatter: canBeLink,
        editor: "input",
        cellClick: (e, cell) => {
          if (!renameFile && cell.getRow().getData().FileId !== 0) {
            this.viewFile(e, cell);
          }
        },
      },
      {
        title: "Redaction",
        field: "HasAnnotations",
        headerSort: false,
        width: "10%",
        formatter: boolFormatter,
        visible: this.props.manageRedaction && this.props.redactionColumn,
      },
      {
        title: "Page Count",
        field: "PageCount",
        headerSort: false,
        width: "10%",
        visible: this.props.fileProperties && this.props.pageCount,
      },
      {
        title: "File Size (Bytes)",
        field: "FileSize",
        formatter: function (cell, formatterParams) {
          if (cell.getValue() != null) {
            return cell.getValue().toLocaleString();
          } else {
            return cell.getValue();
          }
        },
        headerSort: false,
        width: "10%",
        visible: this.props.fileProperties && this.props.fileSize,
      },
      {
        title: "Page Dimensions",
        field: "PageDimensions",
        headerSort: false,
        width: "10%",
        visible: this.props.fileProperties && this.props.pageDimensions,
      },
    ];
    return newColumns;
  };

  setGridFooterText(pageNumber, rowCount) {
    const resultsCountSpan = document.getElementById("resultsCount");
    if (
      resultsCountSpan &&
      this.props.props &&
      this.gridBrowseRef &&
      this.gridBrowseRef.table
    ) {
      let resultsCount = 0;
      if (rowCount == null) {
        resultsCount = this.props.props.length;
      } else {
        resultsCount = rowCount;
      }
      if (resultsCount < 1) {
        resultsCountSpan.innerText = "";
        return;
      }
      const pageSize = this.gridBrowseRef.table.getPageSize();
      const pageFirst = (pageNumber - 1) * pageSize + 1;
      const pageLast = Math.min(pageNumber * pageSize, resultsCount);

      let text = "File count: ";
      if (resultsCount > pageSize) {
        text += `${pageFirst} - ${pageLast} (of ${resultsCount})`;
      } else {
        text += `${pageLast}`;
      }
      resultsCountSpan.innerText = text;
    }
  }

  render() {
    let data = this.props.props;
    this.setGridFooterText(1);

    return (
      <Fragment>
        <div>
          <ReactTabulator
            id="table"
            columns={[]}
            data={data}
            index="FileId"
            ref={(ref) => (this.gridBrowseRef = ref)}
            options={{
              pagination: "local",
              paginationSize: 20,
              placeholder: "No Files For This Folder",
            }}
            footerElement={<span id="resultsCount" style={{ float: "left" }} />}
            pageLoaded={(pageNumber) => {
              this.setGridFooterText(pageNumber);
            }}
          />
        </div>
        <Popup
          contentStyle={{
            width: "95%",
            borderRadius: "15px",
            padding: "15px",
          }}
          open={this.state.viewerOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closeViewer}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closeViewer}>
              &times;
            </a>
            <iframe
              id="imageViewer"
              src={config.ImageViewer}
              title="Image Viewer"
              style={{ width: "100%", height: "90vh" }}
            ></iframe>
          </div>
        </Popup>
      </Fragment>
    );
  }
}

export default BrowseGridView;
