import React, { Component, Fragment } from "react";
import Popup from "reactjs-popup";
import { Button, FormGroup, Checkbox, ControlLabel } from "react-bootstrap";
import { getReposByClientId, updateRepoAccess } from "../../libs/API";

class UpdateReposPopup extends Component {
  _isMounted = false; // Flag to track if component is mounted

  constructor(props) {
    super(props);
    this.state = {
      popupOpen: this.props.props.showUpdateReposPopup,
      email: this.props.props.email,
      username: this.props.props.username,
      repos: this.props.props.repos,
      clientId: this.props.props.selectedClientId,
      fullRepoList: [],
    };
    this.closePopup = this.closePopup.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true; // Set the component as mounted
    this.loadAvailableRepos();
  }

  componentWillUnmount() {
    this._isMounted = false; // Set the component as unmounted
  }

  async loadAvailableRepos() {
    // Load available repos only if component is still mounted
    getReposByClientId(this.state.clientId).then(async (data) => {
      if (this._isMounted) {
        // Check if component is still mounted
        const fullRepoList = data;
        console.log(fullRepoList);
        this.setState({ fullRepoList });
      }
    });
  }

  closePopup() {
    // Close the popup and clear data only if component is still mounted
    if (this._isMounted) {
      this.setState({
        popupOpen: false,
        email: "",
        username: "",
        repos: [],
        fullRepoList: [],
      });
    }
    this.props.unmountMe(); // Perform any parent-level unmount cleanup if necessary
  }

  handleChange = (event) => {
    const id =
      event.target.type === "radio" || event.target.type === "checkbox"
        ? event.target.name
        : event.target.id;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const repoId = parseInt(id);

    let { fullRepoList, repos } = this.state;
    if (value) {
      const addRepo = fullRepoList.find((r) => r.repositoryId === repoId);
      repos.push(addRepo);
    } else {
      repos = repos.filter((repo) => repo.repositoryId !== repoId);
    }
    this.setState({ repos });
  };

  handleUpdateRepos = async (e) => {
    e.preventDefault();
    const { username, repos, email } = this.state;
    updateRepoAccess(username, repos, email).then((data) => {
      console.log(data);
    });
    this.closePopup();
  };

  render() {
    const { popupOpen, email, repos, fullRepoList } = this.state;

    return (
      <Popup
        contentStyle={{
          width: "50%",
          borderRadius: "15px",
          padding: "15px",
        }}
        open={popupOpen}
        modal
        closeOnDocumentClick={false}
        onClose={this.closePopup}
      >
        <div>
          {/* eslint-disable-next-line */}
          <a className="close" onClick={this.closePopup}>
            &times;
          </a>
          <div>
            <h4>Update Repos</h4>
            <hr />
            <div style={{ marginBottom: "10px" }}>
              <b>Email:</b> {email}
            </div>

            <form onSubmit={this.handleUpdateRepos}>
              <FormGroup>
                <ControlLabel>
                  <b>Repos:</b>
                </ControlLabel>
                <div style={{ height: "50vh", overflow: "auto" }}>
                  {fullRepoList.map((repo, i) => (
                    <Fragment key={repo.repositoryId}>
                      <Checkbox
                        key={repo.repositoryId}
                        name={repo.repositoryId}
                        inline
                        onChange={this.handleChange}
                        checked={repos
                          .map((r) => r.repositoryId)
                          .includes(repo.repositoryId)}
                      >
                        {`   ${repo.folderName} / ${repo.owner}`}
                      </Checkbox>
                      <br />
                    </Fragment>
                  ))}
                </div>
              </FormGroup>
              <FormGroup>
                <Button bsClass="btn btn-outline-secondary" type="submit">
                  Update Repos
                </Button>
              </FormGroup>
            </form>
          </div>
        </div>
      </Popup>
    );
  }
}

export default UpdateReposPopup;
