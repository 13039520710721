import React, { Component, Fragment } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Glyphicon,
} from "react-bootstrap";
import LoaderButton from "../LoaderButton";
import * as constants from "../../constants";
import DocumentSync from "../../Component/Images/DocumentSync-Logo.png";
import "../Login/login.css";

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      isSendingCode: false,
      isCodeSent: false,
      confirmationCode: "",
      password: "",
      confirmPassword: "",
      isConfirming: false,
      isConfirmed: false,
      errorMessage: "",
    };
  }

  validateConfirmationCode = () => {
    return this.state.username.length > 0 ? false : true;
  };

  handleCodeButtonClick = async (e) => {
    e.preventDefault();
    this.setState({ isSendingCode: true });
    try {
      await Auth.forgotPassword(this.state.username);
      this.setState({ isCodeSent: true });
    } catch (error) {
      alert(error.message);
      this.setState({ isSendingCode: false });
    }
  };

  validateConfirmationForm = () => {
    if (this.state.confirmationCode === "") {
      this.setState({ errorMessage: "Please enter Confirmation Code" });
      return;
    }
    if (!this.state.password.match(/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/)) {
      this.setState({
        errorMessage: "Minimum 8 Characters including 1 number.",
      });
      return;
    }
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ errorMessage: constants.PASSWORD_MATCH_INVALID });
      return;
    }
    return true;
  };

  handleConfirmationFormButtonClick = async (e) => {
    e.preventDefault();
    if (this.validateConfirmationForm()) {
      this.setState({ isConfirming: true, errorMessage: "" });
      try {
        await Auth.forgotPasswordSubmit(
          this.state.username,
          this.state.confirmationCode,
          this.state.password
        );
        this.setState({ isConfirmed: true });
      } catch (error) {
        this.setState({ isConfirming: false });
      }
    }
  };

  renderConfirmationCode = () => {
    return (
      <div>
        <form onSubmit={this.handleCodeButtonClick} autoComplete="off">
          <FormGroup controlId="username">
            <ControlLabel>Email:</ControlLabel>
            <FormControl
              componentClass="input"
              onChange={(e) => this.setState({ username: e.target.value })}
              type="text"
            ></FormControl>
          </FormGroup>
          <FormGroup>
            <Link to="/">Return to Login Page</Link>
          </FormGroup>
          <FormGroup>
            <LoaderButton
              block
              bsClass="btn btn-outline-secondary"
              bsSize="large"
              disabled={this.validateConfirmationCode()}
              type="submit"
              isLoading={this.state.IsSendingCode}
              text="Send Password Reset Code"
              loadingText="Sending Password Reset Code…"
            />
          </FormGroup>
        </form>
      </div>
    );
  };

  renderConfirmationForm = () => {
    return (
      <div>
        <form
          onSubmit={this.handleConfirmationFormButtonClick}
          autoComplete="off"
        >
          <HelpBlock>
            Please check your email ({this.state.username}) for the confirmation
            code.
          </HelpBlock>
          <br />
          <br />
          <FormGroup bsSize="large" controlId="confirmationCode">
            <ControlLabel>Confirmation Code</ControlLabel>
            <FormControl
              type="text"
              autoFocus
              autoComplete="off"
              onChange={(e) =>
                this.setState({ confirmationCode: e.target.value })
              }
            />
          </FormGroup>
          <hr />
          <FormGroup bsSize="large" controlId="password">
            <ControlLabel>New Password</ControlLabel>
            <span
              style={{ marginLeft: "3%", fontSize: "small", color: "gray" }}
            >
              (Minimum 8 Characters including 1 number.)
            </span>
            <FormControl
              type="password"
              autoComplete="new-password"
              onChange={(e) => this.setState({ password: e.target.value })}
              minLength={8}
            />
          </FormGroup>
          <FormGroup bsSize="large" controlId="confirmPassword">
            <ControlLabel>Confirm Password</ControlLabel>
            <FormControl
              type="password"
              autoComplete="new-password"
              onChange={(e) =>
                this.setState({ confirmPassword: e.target.value })
              }
              minLength={8}
            />
          </FormGroup>
          <FormGroup>
            <Link to="/">Return to Login Page</Link>
          </FormGroup>
          <FormGroup>
            <LoaderButton
              block
              type="submit"
              bsSize="small"
              bsClass="btn btn-outline-secondary"
              isLoading={this.state.isConfirming}
              text="Confirm"
              loadingText="Confirming…"
            ></LoaderButton>
          </FormGroup>
          <span className="errorMessage">{this.state.errorMessage}</span>
        </form>
      </div>
    );
  };

  renderSuccessMessage = () => {
    return (
      <div className="success">
        <Glyphicon glyph="ok" />
        <p>Your password has been reset.</p>
        <p>
          <Link to="/">Click here to login with your new credentials.</Link>
        </p>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <div id="loginmain">
          <div id="loginheader"></div>
          <div id="logo">
            <img src={DocumentSync} alt="DocumentSync" />
          </div>
          <div id="logincontent">
            <br />
            <br />
            {!this.state.isCodeSent
              ? this.renderConfirmationCode()
              : !this.state.isConfirmed
              ? this.renderConfirmationForm()
              : this.renderSuccessMessage()}
          </div>
          <div id="footer">
            <p>
              &copy; {new Date().getFullYear()} Foveonics Document Solutions
            </p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ResetPassword;
