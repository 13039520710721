import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import DocumentSync from "../../src/Component/Images/DS2Logo.jpg";
import { saveAuditTrail } from "../libs/API";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // console.log("header props", props);
  }

  clearTokens = () => {
    this.props.props.hasAuthenticated = false;
    this.props.props.loggedInUser = "";
    this.props.props.hasManageMetadataTemplate = false;
    this.props.props.hasManageRepositories = false;
    this.props.props.hasEditIndex = false;
    this.props.props.hasManageRedaction = false;
    this.props.props.hasManageUsers = false;
    this.props.props.hasManageFiles = false;
    this.props.props.hasTextSearch = false;
    this.props.props.hasDelete = false;
    this.props.props.hasCliftonWorkflow = false;
    this.props.props.hasMedfordWorkflow = false;
    this.props.props.hasSendEmail = false;
    this.props.props.hasRestrictAccessByIP = false;
    this.props.props.hasFoveonicsAdmin = false;
    this.props.props.hasOcrRole = false;
    this.props.props.hasPIIRole = false;
    this.props.props.hasAberdeenWorkflow = false;
    this.props.props.hasGlenrockWorkflow = false;
  };

  handleClick = async () => {
    //const res = await getGeoLoaction();
    let auditDetails = {
      emailAddress: this.props.props.loggedInUser,
      ipAddress: this.props.props.userIpAddress, //res.IPv4,
      eventLog: "LogOut",
    };

    //saving audit trail details into dynomo db
    saveAuditTrail(auditDetails).then((data) => {});
    this.clearTokens();
    this.props.history.push("/");
    await Auth.signOut();
  };

  handleMenuClick = (e) => {
    const { id } = e.target;
    this.props.history.push(`/${id}`);
  };

  render() {
    return (
      <div>
        <div id="header">
          {/* eslint-disable-next-line */}
          <a onClick={this.handleMenuClick}>
            <img
              id="browse"
              src={DocumentSync}
              alt="Browse Repository"
              title="Browse Repository"
            />
          </a>
          <Button
            style={{ marginLeft: "2%" }}
            bsSize="small"
            onClick={this.handleClick}
          >
            Logout
          </Button>
          <Button
            id="helpPage"
            bsSize="small"
            onClick={(e) => {
              e.preventDefault();
              window.open("https://help.documentsync.com", "_blank");
              // window.location.href = "https://help.documentsync.com";
            }}
          >
            Help
          </Button>
          {this.props.props.hasManageMetadataTemplate && (
            <Button
              id="manageindextemplate"
              bsSize="small"
              onClick={this.handleMenuClick}
            >
              Manage Index Template
            </Button>
          )}
          {this.props.props.hasOcrRole && (
            <Button
              id="ocrindextempates"
              bsSize="small"
              onClick={this.handleMenuClick}
            >
              OCR
            </Button>
          )}
          {this.props.props.hasManageRepositories && (
            <Button
              id="managerepository"
              bsSize="small"
              onClick={this.handleMenuClick}
            >
              Manage Repository
            </Button>
          )}

          {this.props.props.hasManageUsers && (
            <Button
              id="manageusers"
              bsSize="small"
              onClick={this.handleMenuClick}
            >
              Manage Users
            </Button>
          )}
          <Button id="myaccount" bsSize="small" onClick={this.handleMenuClick}>
            My Account
          </Button>
        </div>
        <div
          style={{
            textAlign: "right",
            width: "98%",
            float: "right",
            marginTop: "-0.1rem",
          }}
        >
          <Button
            bsSize="small"
            style={{
              cursor: "default",
              fontSize: "small",
              color: "grey",
              float: "right",
            }}
          >
            Logged in as: {this.props.props.loggedInUser}
          </Button>
        </div>
      </div>
    );
  }
}

export default Header;
