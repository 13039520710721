import React, { Component, Fragment } from "react";
import { ControlLabel, FormGroup, FormControl, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { getRepos } from "../../libs/API";
import Header from "../header";
import LoaderButton from "../LoaderButton";
import * as constants from "../../constants";
import "./myaccount.css";
import EnableTotpPopup from "../Login/enabletotppopup";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      newPassword: "",
      confirmPassword: "",
      errorMessage: "",
      isLoading: false,
      repoList: [],
      groups: [],
      showEnableTotpPopup: false,
      showDisableButton: "",
    };
  }

  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
      // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    if (user.preferredMFA === "NOMFA") {
      this.setState({ showDisableButton: "false" });
    } else {
      this.setState({ showDisableButton: "true" });
    }
    await this.loadRepos();
  }

  handleSubmit = async (e) => {
    this.setState({ successMessage: "" });
    e.preventDefault();
    if (!this.state.newPassword.match(/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/)) {
      this.setState({
        errorMessage: "Minimum 8 Characters including 1 number.",
      });
      return;
    }
    if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        errorMessage: constants.PASSWORD_MATCH_INVALID,
      });
      return;
    }
    await this.handleSavePassword();
  };

  resetInput = () => {
    let allInputs = document.querySelectorAll("input");
    if (allInputs.length > 0) {
      allInputs.forEach((input) =>
        input.id !== "username" ? (input.value = "") : input.value
      );
    }
  };

  handleSavePassword = async () => {
    this.setState({ isLoading: true, errorMessage: "", successMessage: "" });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          this.state.password,
          this.state.newPassword
        );
      })
      .then(
        (data) => {
          this.setState({ isLoading: false });
          if (data === "SUCCESS") {
            this.resetInput();
            this.setState({
              successMessage: "Password Changed succesfully",
              isLoading: false,
            });
          }
        },
        (err) => {
          console.log(err);
          this.setState({
            errorMessage: err.message,
            isLoading: false,
          });
        }
      );
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    this.setState({ errorMessage: "" });
  };

  getAuthHeader = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    return { headers: { Authorization: `Bearer ${token}` } };
  };

  loadRepos = async () => {
    getRepos().then((data) => {
      this.setState({ repoList: data });
    });
    await this.loadGroups();
  };

  loadGroups = async () => {
    // const user = await Auth.currentAuthenticatedUser();
    // const groups = user.signInUserSession.idToken.payload["cognito:groups"];
    this.setState({ groups: this.props.props.groups });
  };

  formatGroupDisplay(group) {
    switch (group) {
      case "DocumentSync_ManageRedactions":
        return "Manage Redactions - Add, edit, and see through redaction annotations. Export with or without redactions";
      case "DocumentSync_TextSearch":
        return "Text Search - Perform text search of OCRed documents";
      case "DocumentSync_ManageUsers":
        return "Manage Users - Add and delete users, manage repository access and privileges";
      case "DocumentSync_EditIndex":
        return "Edit Index - Update index data from viewer";
      case "DocumentSync_ManageMetadataTemplates":
        return "Manage Index Templates - Create, delete, and edit Index Templates";
      case "DocumentSync_ManageRepositories":
        return "Manage Repositories - Create, delete, and edit names and S3 location of repositories";
      case "DocumentSync_Delete":
        return "Delete - Delete files, folders, and pages";
      case "DocumentSync_ManageFiles":
        return "Manage Files - Add, rename, and move files";
      case "DocumentSync_CliftonExpungementWorkflow":
        return "Clifton Expungement Workflow - Allows Clifton NJ PD Expungement and Juvenile Workflow";
      case "DocumentSync_MedfordExpungementWorkflow":
        return "Medford Expungement Workflow - Allows Medford NJ PD Expungement Workflow";
      case "DocumentSync_EnglewoodExpungementWorkflow":
        return "Englewood Expungement Workflow - Allows Englewood NJ PD Expungement Workflow";

      case "DocumentSync_GlenrockExpungementWorkflow":
        return "Glenrock Expungement Workflow - Allows Glenrock PD Expungement Workflow";

      case "DocumentSync_AberdeenExpungementWorkflow":
        return "Aberdeen Expungement Workflow - Allows Aberdeen Township Expungement Workflow";
      case "DocumentSync_OCR":
        return "Ocr - Allows User to OCR templates based on Repositories";
      case "DocumentSync_PII":
        return "PII - Allows User to redact PII in files from  the image viewer";
      case "DocumentSync_Print":
        return "Print with Options - Print from viewer and show the machine's print dialogue box";
      case "DocumentSync_Email":
        return "Email - Email file from viewer or email file(s) from browse / search pages";
      case "DocumentSync_Download":
        return "Download - Download file from viewer, or download file(s) from browse / search pages";
      case "FoveonicsAdmin":
        return "Foveonics Admin - Has full access and privileges to all repositories, can run OCR jobs, and can run workflows";
      case "DocumentSync_DownloadIndex":
        return "This role allows the user to download the index information from the search page";
      case "DocumentSync_FileProperties":
        return "Show page count on the browse and search screens for users with the File Properties Role";
      default:
        return null;
    }
  }

  renderEnableTotpPopup() {
    return (
      <Fragment>
        {this.state.showEnableTotpPopup && (
          <EnableTotpPopup
            props={{
              showEnableTotpPopup: this.state.showEnableTotpPopup,
            }}
            unmountMe={this.handleChildUnmount}
            handleHideEnableButton={this.handleHideEnableButton}
          />
        )}
      </Fragment>
    );
  }

  handleChildUnmount = () => {
    this.setState({
      showEnableTotpPopup: false,
    });
  };

  handleHideEnableButton = () => {
    this.setState({
      showDisableButton: "true",
    });
  };

  handleEnableTotpPopup = (e) => {
    e.preventDefault();
    this.setState({
      showEnableTotpPopup: true,
    });
  };

  handleDisableTotpPopup = async (e) => {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
      // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    Auth.setPreferredMFA(user, "NOMFA");
    this.setState({ showDisableButton: "false" });
    alert("MFA Disabled");
  };

  renderEnableDisableButton = () => {
    return (
      <Fragment>
        {this.state.showDisableButton === "false" && (
          <Button
            bsSize="small"
            bsClass="btn btn-outline-secondary"
            onClick={this.handleEnableTotpPopup}
            style={{ marginRight: "5px" }}
          >
            Enable MFA
          </Button>
        )}
        {this.state.showDisableButton === "true" && (
          <Button
            bsSize="small"
            bsClass="btn btn-outline-secondary"
            onClick={this.handleDisableTotpPopup}
            style={{ float: "right" }}
          >
            Disable MFA
          </Button>
        )}
      </Fragment>
    );
  };

  render() {
    const { groups, repoList } = this.state;
    return (
      <Fragment>
        <Header props={this.props.props} history={this.props.history} />
        <div className="maindiv">
          <h4>My Account</h4>
          <br />
          <div className="myRepoandPrivilege">
            <fieldset className="my-fieldset">
              <legend className="login-legend">
                Repository Access and Privilege(s)
              </legend>
              <div style={{ float: "right" }}>
                {this.renderEnableDisableButton()}
              </div>
              <h6>Repository Access List</h6>
              <ol>
                {repoList.length > 0 &&
                  repoList.map((repo, i) => (
                    <li key={repo.folderName + i}>{repo.folderName}</li>
                  ))}
              </ol>
              <h6>Privilege(s)</h6>
              <ol>
                {groups.length > 0 &&
                  groups
                    .sort()
                    .filter(
                      (group) =>
                        group !== "DocumentSync" && !group.startsWith("OPRA_")
                    )
                    .map((group) => (
                      <li key={group}>{this.formatGroupDisplay(group)}</li>
                    ))}
              </ol>
            </fieldset>
          </div>

          <div className="myaccountContentRight">
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              <fieldset className="my-fieldset">
                <legend className="login-legend">Change Password</legend>
                <FormGroup controlId="username">
                  <ControlLabel>Username:</ControlLabel>
                  <FormControl
                    componentClass="input"
                    readOnly
                    type="text"
                    value={this.props.props.loggedInUser}
                    autoComplete="off"
                  ></FormControl>
                </FormGroup>
                <FormGroup controlId="password">
                  <ControlLabel>Current Password:</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="password"
                    autoComplete="new-password"
                    required
                    minLength={8}
                    onChange={this.handleChange}
                  ></FormControl>
                </FormGroup>
                <FormGroup controlId="newPassword">
                  <ControlLabel>New Password:</ControlLabel>
                  <span className="PasswordText">
                    (Minimum 8 Characters including 1 number.)
                  </span>
                  <FormControl
                    componentClass="input"
                    type="password"
                    autoComplete="new-password"
                    required
                    onChange={this.handleChange}
                    minLength={8}
                  ></FormControl>
                </FormGroup>
                <FormGroup controlId="confirmPassword">
                  <ControlLabel>Confirm Password:</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="password"
                    autoComplete="new-password"
                    required
                    minLength={8}
                    onChange={this.handleChange}
                  ></FormControl>
                </FormGroup>
                <FormGroup>
                  <LoaderButton
                    block
                    bsClass="btn btn-outline-secondary"
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Save New Password"
                    loadingText="Saving New Password…"
                  />
                </FormGroup>
                <span className="errorMessage">{this.state.errorMessage}</span>
                <span className="successMessage">
                  {this.state.successMessage}
                </span>
              </fieldset>
            </form>
            {this.renderEnableTotpPopup()}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MyAccount;
