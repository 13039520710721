import React, { Component, Fragment } from "react";
import {
  FormGroup,
  ControlLabel,
  Button,
  FormControl,
  HelpBlock,
} from "react-bootstrap";
import Popup from "reactjs-popup";
import { Auth } from "aws-amplify";
import QRCode from "qrcode.react";
// import LoaderButton from "../LoaderButton";

class EnableTotpPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      isLoading: false,
      user: "",
      QrCode: "",
      showMfa: false,
      mfaToken: "",
    };
    // console.log("Expungement popup props", props);
  }

  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
      // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    this.setState(
      {
        popupOpen: this.props.props.showEnableTotpPopup,
        user: user,
      },
      async () => await this.enableTOTP()
    );
  }

  enableTOTP = async (e) => {
    await Auth.setupTOTP(this.state.user).then((code) => {
      // You can directly display the `code` to the user or convert it to a QR code to be scanned.
      // E.g., use following code sample to render a QR code with `qrcode.react` component:
      let QrCode =
        "otpauth://totp/AWSCognito:" +
        "Documentsync-MFA" +
        "?secret=" +
        code +
        "&issuer=" +
        "www.DocumentSync.com";
      this.setState({ QrCode: QrCode }, () => this.setState({ showMfa: true }));
    });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
    this.props.unmountMe();
  };

  handleMfaConfirmation = async (e) => {
    e.preventDefault();
    Auth.verifyTotpToken(this.state.user, this.state.mfaToken)
      .then(async () => {
        // don't forget to set TOTP as the preferred MFA method
        Auth.setPreferredMFA(this.state.user, "TOTP");
        this.closePopup();
        this.props.handleHideEnableButton();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    return (
      <Fragment>
        <Popup
          contentStyle={{
            width: "590px",
            borderRadius: "15px",
            padding: "15px",
            minWidth: "590px !important",
          }}
          open={this.state.popupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closePopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            {this.state.showMfa && (
              <div>
                <form onSubmit={this.handleMfaConfirmation}>
                  <FormGroup>
                    <HelpBlock>
                      Scan The QR code with preferred authenticator app (For
                      example Google Authenticator)
                    </HelpBlock>
                  </FormGroup>
                  <FormGroup>
                    <QRCode value={this.state.QrCode} />
                  </FormGroup>
                  <FormGroup controlId="MFA">
                    <ControlLabel>
                      Enter Code From Authenticator to setup MFA:
                    </ControlLabel>
                    <FormControl
                      componentClass="input"
                      onChange={(e) =>
                        this.setState({ mfaToken: e.target.value })
                      }
                      type="text"
                      autoComplete="off"
                      required
                    ></FormControl>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      bsSize="small"
                      bsClass="btn btn-outline-secondary"
                      type="submit"
                    >
                      Enable MFA
                    </Button>
                  </FormGroup>
                </form>
              </div>
            )}
          </div>
        </Popup>
      </Fragment>
    );
  }
}
export default EnableTotpPopup;
