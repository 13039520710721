import React, { Component, Fragment } from "react";
import Popup from "reactjs-popup";
import { FormGroup, Row, Col, FormControl } from "react-bootstrap";
import Upload from "./upload";
import { getTemplatesBasedOnRepositoryId } from "../../libs/API";

class UploadFilesPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexTemplateData: [],
      popupOpen: false,
      isLoading: false,
      folderName: "",
      tableName: "",
    };
    // console.log("add folder popup props", props);
  }

  async componentDidMount() {
    this.setState({ popupOpen: this.props.props.showUploadFilesPopup });
    await this.loadTemplates();
  }

  closePopup = () => {
    this.setState({ popupOpen: false });
    this.props.unmountMe();
  };

  handleUploadFilesSubmit = (e) => {
    e.preventDefault();
  };

  loadTemplates = async () => {
    getTemplatesBasedOnRepositoryId(this.props.props.mainNode).then((data) => {
      if (data) {
        this.setState({ indexTemplateData: data }, () =>
          this.onLoadTemplates()
        );
      }
    });
  };

  onLoadTemplates = async () => {
    //when the user has access to only one template, hide the first option and auto select the first index
    if (
      this.state &&
      this.state.indexTemplateData &&
      this.state.indexTemplateData.length === 1
    ) {
      const templateSelect = document.getElementById("selectTemplates");
      templateSelect.options[0].style.display = "none";
      templateSelect.selectedIndex = "1";
      templateSelect.dispatchEvent(new Event("change", { bubbles: true }));
    }
  };

  render() {
    return (
      <Fragment>
        <Popup
          contentStyle={{
            width: "32%",
            borderRadius: "15px",
            padding: "15px",
          }}
          open={this.state.popupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closePopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            <div>
              <h5>Upload Files - {this.props.props.filePath}</h5>
              <br />
              <Row>
                <Col sm={12}>
                  <FormGroup controlId="selectTemplates">
                    <FormControl
                      componentClass="select"
                      required
                      onChange={(e) =>
                        this.setState({ tableName: e.target.value })
                      }
                    >
                      <option key={-1} value="">
                        Select Index Template
                      </option>
                      {this.state.indexTemplateData.map((template, i) => (
                        <option key={i} value={template.tableName}>
                          {template.displayName}
                        </option>
                      ))}
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Upload
                  tableName={this.state.tableName}
                  nodeId={this.props.props.parentNodeId}
                  closePopup={this.closePopup}
                  addNewTabulatorRow={this.props.addNewTabulatorRow}
                />
              </FormGroup>
            </div>
          </div>
        </Popup>
      </Fragment>
    );
  }
}

export default UploadFilesPopup;
