import React, { Fragment, Component } from "react";
import Header from "../header";
import "./manageindextemplate.css";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import { FormControl, ControlLabel, Row, Col, Button } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import LoaderButton from "../LoaderButton";
import RenderFieldPopup from "./renderfieldpopup";
import {
  getTemplates2,
  getRepositories,
  deleteTemplate,
  getAllTemplateColumns,
  addIndexTemplateData,
  updateFieldData,
  deleteColTemplate,
  addFieldData,
} from "../../libs/API";

class ManageIndexTemplate extends Component {
  gridTemplateRef = null;
  gridTempColumnsRef = null;
  constructor(props) {
    super(props);
    this.state = {
      indexTemplateData: [],
      repoData: [],
      displayName: "",
      repositoryId: "",
      repositoryOwner: "",
      isLoading: false,
      selectedTemplateData: {
        displayName: "",
        repositoryOwner: "",
        tableName: "",
      },
      templateColumns: [],
      showFieldPopup: false,
      addField: {
        fieldName: "",
        fieldType: "",
        fieldLength: "",
      },
    };
  }

  async componentDidMount() {
    await this.loadTemplates();
    await this.getAllRepositories();
  }

  loadTemplates = async () => {
    getTemplates2().then((data) => {
      if (data) {
        this.setState({ indexTemplateData: data });
      }
    });
  };

  getAllRepositories = async () => {
    getRepositories().then((data) => {
      if (data) {
        this.setState({ repoData: data });
      }
    });
  };

  deleteTemplateData = async (e, cell) => {
    e.preventDefault();
    const tableName = cell.getRow().getData().tableName;
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteTemplate(tableName).then((data) => {
              if (data.status === 200) {
                cell.getRow().delete();
              }
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  getAllTemplatesColumns = async (e, cell) => {
    e.preventDefault();
    const tableName = cell.getRow().getData().tableName;
    const selectedDisplayName = cell.getRow().getData().displayName;
    const selectedRepositoryOnwer = cell.getRow().getData().repositoryOwner;
    getAllTemplateColumns(tableName).then((data) => {
      if (data) {
        this.setState({
          selectedTemplateData: {
            displayName: selectedDisplayName,
            repositoryOwner: selectedRepositoryOnwer,
            tableName: tableName,
          },
          templateColumns: data,
        });
        document.getElementsByClassName("rightdivTemplate")[0].style.display =
          "block";
      }
    });
  };

  addNewTemplate = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let repositoryId = this.state.repositoryId;
    let tablename = this.state.displayName;
    let addNewTemplateParams = {
      tableName: tablename,
      repositoryId: repositoryId,
    };

    try {
      const data = await addIndexTemplateData(addNewTemplateParams);
      let newIndexTemplateRow = {
        repositoryOwner: this.state.repositoryOwner,
        displayName: this.state.displayName,
        tableName: data,
      };
      this.gridTemplateRef.table.addRow(newIndexTemplateRow, false);
    } catch (error) {
      // Handle the error, e.g., display an error message to the user.
      console.error("Error while adding a new template:", error);
    } finally {
      this.setState({
        isLoading: false,
      });
      document.getElementById("templateName").value = "";
      document.getElementById("templatedropdown").selectedIndex = 0;
    }
  };

  updateDeleteFields = async (e, cell) => {
    e.preventDefault();
    if (e.target.innerText === "Update") {
      let rowData = cell.getRow().getData();
      let inputOldValue = cell.getRow().getCells()[0].getOldValue();
      let hiddenFieldData =
        cell.getRow().getCells()[3].getValue() === undefined
          ? false
          : cell.getRow().getCells()[3].getValue();
      Object.assign(rowData, {
        tableName: this.state.selectedTemplateData.tableName,
        inputOldValue: inputOldValue,
        hiddenFieldData: hiddenFieldData,
      });
      updateFieldData(rowData).then((data) => {
        if (data.status === 200) {
          alert("Updated");
        }
      });
    } else if (e.target.innerText === "Delete") {
      this.showAlert(cell);
    }
  };

  showAlert = (cell) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let deleteParams = {
              fieldName: cell.getRow().getData().inputName,
              tableName: this.state.selectedTemplateData.tableName,
            };
            deleteColTemplate(deleteParams).then((data) => {
              if (data.status === 200) {
                cell.getRow().delete();
              }
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  handleControlledList = (e) => {
    this.setState({ showFieldPopup: true });
  };

  addField = async (e) => {
    e.preventDefault();
    try {
      let addParams = {
        tableName: this.state.selectedTemplateData.tableName,
        fieldName: this.state.addField.fieldName,
        fieldType: this.state.addField.fieldType,
        fieldLength: this.state.addField.fieldLength,
      };

      const data = await addFieldData(addParams);

      if (data.status === 200) {
        let needle = ["String", "List"];
        let result = needle.some((i) => addParams.fieldType.includes(i));
        if (!addParams.fieldLength && result) {
          addParams.fieldLength = 50;
        }
        let newField = {
          inputName: addParams.fieldName,
          dataType: addParams.fieldType,
          dataTypeLength: addParams.fieldLength,
        };
        this.gridTempColumnsRef.table.addRow(newField, false);
      } else {
        // Handle the case where the response status is not 200
        console.error("Field addition failed with status:", data.status);
      }
    } catch (error) {
      // Handle any errors that occur during the execution
      console.error("An error occurred:", error);
    } finally {
      document.getElementById("filedLength").value = "";
      document.getElementById("filedName").value = "";
      document.getElementById("dataType").selectedIndex = 0;
    }
  };

  handleChildUnmount = () => {
    this.setState({ showFieldPopup: false });
  };

  renderIndextemplateRepo() {
    let addButton = function () {
      let button =
        "<button type='button'class='btn btn-outline-secondary btn-sm updatedeletebtnindextemplate' >Delete</button>";
      return button;
    };

    let addUpdateandDeleteButton = function () {
      let button =
        "<div><button type='button'class='btn btn-outline-secondary btn-sm updatedeletebtnindextemplate' >Update</button> <button type='button'class='btn btn-outline-secondary btn-sm updatedeletebtnindextemplate' >Delete</button></div>";
      return button;
    };

    let editCheck = (cell) => {
      return !(cell.getRow().getData().dataType === "Date");
    };

    return (
      <Fragment>
        <h4> Manage Index Templates</h4>
        <br />
        <div className="maintemplateDiv">
          <div className="leftdivTemplate">
            <form
              onSubmit={this.addNewTemplate}
              className="indexTemplatemargin"
            >
              <Row>
                <Col sm={4}>
                  <FormControl
                    className="input-box"
                    type="input"
                    id="templateName"
                    onChange={(e) =>
                      this.setState({ displayName: e.target.value })
                    }
                    required
                  />
                </Col>
                <Col sm={5}>
                  <FormControl
                    className="customindexTemplateSelect"
                    componentClass="select"
                    id="templatedropdown"
                    onChange={(e) =>
                      this.setState({
                        repositoryId:
                          e.target.options[
                            e.target.options.selectedIndex
                          ].getAttribute("data-key"),
                        repositoryOwner: e.target.value,
                      })
                    }
                    required
                  >
                    <option key={-1} value="">
                      Select Index Template
                    </option>
                    {this.state.repoData.map((repo, i) => (
                      <option
                        key={i}
                        data-key={repo.repositoryId}
                        value={repo.repositoryOwner}
                      >
                        {repo.repositoryOwner}
                      </option>
                    ))}
                  </FormControl>
                </Col>
                <Col sm={3}>
                  <LoaderButton
                    block
                    bsClass="btn btn-outline-secondary"
                    disabled={this.state.isLoading}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Add New Template"
                    loadingText="Adding Template..."
                  />
                </Col>
              </Row>
            </form>
            <ReactTabulator
              id="templateTable"
              columns={[
                {
                  title: "Template Name",
                  field: "displayName",
                  formatter: "link",
                  cellClick: (e, cell) => {
                    this.getAllTemplatesColumns(e, cell);
                  },
                },
                {
                  title: "Repository Owner",
                  field: "repositoryOwner",
                },
                {
                  title: "",
                  headerSort: false,
                  formatter: addButton,
                  hozAlign: "center",
                  cellClick: (e, cell) => {
                    this.deleteTemplateData(e, cell);
                  },
                },
              ]}
              data={this.state.indexTemplateData}
              ref={(ref) => (this.gridTemplateRef = ref)}
              options={{
                pagination: "local",
                paginationSize: 10,
                placeholder: "No Data Available",
              }}
            />
          </div>
          <div className="rightdivTemplate">
            <div
              style={{
                margin: "1px",
              }}
            >
              <ControlLabel className="controlLableindextemp">
                Templates: {this.state.selectedTemplateData.displayName}
              </ControlLabel>
              <ControlLabel className="controlLableindextemp">
                Repository Owner:
                {this.state.selectedTemplateData.repositoryOwner}
              </ControlLabel>
              <Button bsStyle="link" onClick={this.handleControlledList}>
                Edit Controlled List
              </Button>
            </div>
            <ReactTabulator
              id="templateColumnTable"
              columns={[
                {
                  title: "Filed Name",
                  field: "inputName",
                  headerHozAlign: "center",
                  hozAlign: "center",
                  editor: "input",
                },
                {
                  title: "Data Type",
                  field: "dataType",
                  headerHozAlign: "center",
                  hozAlign: "center",
                  editor: "select",
                  editorParams: {
                    String: "String",
                    Number: "Number",
                    List: "List",
                    Date: "Date",
                  },
                },
                {
                  title: "Filed Length",
                  field: "dataTypeLength",
                  headerHozAlign: "center",
                  hozAlign: "center",
                  editor: "input",
                  editable: editCheck,
                },
                {
                  title: "Hidden",
                  field: "hidden",
                  headerHozAlign: "center",
                  hozAlign: "center",
                  editor: true,
                  formatter: "tickCross",
                },
                {
                  title: "",
                  headerSort: false,
                  formatter: addUpdateandDeleteButton,
                  hozAlign: "center",
                  cellClick: (e, cell) => {
                    this.updateDeleteFields(e, cell);
                  },
                },
              ]}
              data={this.state.templateColumns}
              ref={(ref) => (this.gridTempColumnsRef = ref)}
              options={{
                pagination: "local",
                paginationSize: 12,
                placeholder: "No Data Available",
              }}
            />
            <div className="addfieldDiv">
              <form onSubmit={this.addField}>
                <Row>
                  <Col sm={4}>
                    <FormControl
                      className="input-box"
                      type="input"
                      required
                      id="filedName"
                      onChange={(e) =>
                        this.setState({
                          addField: {
                            ...this.state.addField,
                            fieldName: e.target.value,
                          },
                        })
                      }
                    />
                  </Col>
                  <Col sm={3}>
                    <FormControl
                      className="customindexTemplateSelect"
                      componentClass="select"
                      id="dataType"
                      onChange={(e) =>
                        this.setState({
                          addField: {
                            ...this.state.addField,
                            fieldType: e.target.value,
                          },
                        })
                      }
                      required
                    >
                      <option key={-1} value="">
                        Select
                      </option>
                      <option key={0} value="String">
                        String
                      </option>
                      <option key={1} value="Number">
                        Number
                      </option>
                      <option key={2} value="List">
                        List
                      </option>
                      <option key={3} value="Date">
                        Date
                      </option>
                    </FormControl>
                  </Col>
                  <Col sm={3}>
                    <FormControl
                      className="input-box"
                      type="input"
                      id="filedLength"
                      onChange={(e) =>
                        this.setState({
                          addField: {
                            ...this.state.addField,
                            fieldLength: e.target.value,
                          },
                        })
                      }
                    />
                  </Col>
                  <Col sm={2}>
                    <Button
                      bsClass="btn btn-outline-secondary"
                      bsSize="small"
                      type="submit"
                    >
                      Add Field
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="manageindextemp-main">
          <Header props={this.props.props} history={this.props.history} />
          <div className="managerepo-content">
            {this.renderIndextemplateRepo()}
            {this.state.showFieldPopup ? (
              <RenderFieldPopup
                props={{
                  showFieldPopup: this.state.showFieldPopup,
                  tableName: this.state.selectedTemplateData.tableName,
                }}
                unmountMe={this.handleChildUnmount}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ManageIndexTemplate;
