import React, { Fragment, Component } from "react";
import { Button, FormControl, Row, Col, ControlLabel } from "react-bootstrap";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Header from "../header";
import LoaderButton from "../LoaderButton";
import {
  deleteRepository,
  addRepoData,
  getRepositories,
  updateRepoData,
  getAllClients,
  getRepositoriesByClient,
  saveNewClient,
} from "../../libs/API";
import "./managerepository.css";

class ManageRepository extends Component {
  gridRepoRef = null;
  constructor(props) {
    super(props);
    this.state = {
      repoData: [],
      clients: [],
      repositoryOwner: "",
      folderName: "",
      s3Path: "",
      message: "",
      repositoryId: 0,
      isLoading: false,
      selectedClient: "",
      clientId: this.props.props.clientId,
      showAddClientForm: false,
      newClientName: "",
    };
    // console.log("ManageRepository", props.props.token);
  }
  async componentDidMount() {
    if (this.state.clientId && this.state.clientId > 0) {
      await this.getAllRepositories();
    } else {
      await this.getAllClients();
    }
  }

  getAllRepositories = async () => {
    getRepositories().then((data) => {
      if (data) {
        this.setState({ repoData: data });
      }
    });
  };

  getAllClients = async () => {
    getAllClients().then((data) => {
      if (data) {
        this.setState({ clients: data });
      }
    });
  };

  toggleAddClientForm = () => {
    this.setState((prevState) => ({
      showAddClientForm: !prevState.showAddClientForm,
    }));
  };

  handleNewClientNameChange = (event) => {
    this.setState({ newClientName: event.target.value });
  };

  saveNewClient = async () => {
    try {
      // Call the API function to save the new client
      const newClient = await saveNewClient(this.state.newClientName);

      // Update the clients state with the new client
      this.setState((prevState) => ({
        clients: [...prevState.clients, newClient].sort((a, b) => {
          if (!a.name) return 1; // Move undefined or null `name` to the end
          if (!b.name) return -1; // Move undefined or null `name` to the end
          return a.name.localeCompare(b.name); // Sort by `name` in ascending order
        }),
        newClientName: "",
        showAddClientForm: false,
      }));
    } catch (error) {
      console.error("Error saving new client:", error);
      // Handle the error appropriately
    }
  };

  addNewRow = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });

    var currentClientId = this.state.selectedClient;

    // check if the client is selected
    if (!currentClientId || currentClientId < 1) {
      currentClientId = this.state.clientId;
    }

    if (!currentClientId || currentClientId < 1) {
      alert("No Client data found!");
    }

    try {
      let newRow = {
        repositoryOwner: this.state.repositoryOwner,
        folderName: this.state.folderName,
        directoryPath: this.state.s3Path,
        repositoryId: this.state.repositoryId,
        clientId: currentClientId,
      };

      const data = await addRepoData(newRow);

      if (data.errorMessage.length === 0) {
        newRow = data;
        this.gridRepoRef.table.addRow(newRow, false);
      } else {
        this.setState({ message: data.errorMessage });
      }
    } catch (error) {
      // Handle any errors that occur during the execution
      console.error("An error occurred:", error);
    } finally {
      this.setState({ isLoading: false });
      document.getElementById("repoOwner").value = "";
      document.getElementById("folderName").value = "";
    }
  };

  showAlert = (cell) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let repositoryId = cell.getRow().getData().repositoryId;
            deleteRepository(repositoryId).then((data) => {
              if (data.status === 200) {
                cell.getRow().delete();
              }
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  updateData = async (e, cell) => {
    e.preventDefault();
    if (e.target.innerText === "Update") {
      const rowData = cell.getRow().getData();
      updateRepoData(rowData).then((data) => {
        if (data.status === 200) {
          alert("Updated");
        }
      });
    } else if (e.target.innerText === "Delete") {
      this.showAlert(cell);
    }
  };

  //handleClientChange = (event) => {
  //  this.setState({ selectedClient: event.target.value });
  //}

  handleClientChange = async (event) => {
    const selectedClientId = event.target.value;
    this.setState({ selectedClient: selectedClientId, isLoading: true });

    try {
      if (selectedClientId) {
        const repositories = await getRepositoriesByClient(selectedClientId);
        this.setState({ repoData: repositories });

        // Update the tabular grid with the new data
        if (this.gridRepoRef && this.gridRepoRef.table) {
          this.gridRepoRef.table.setData(repositories);
        }
      } else {
        // If no client is selected, fetch all repositories
        //await this.getAllRepositories();
      }
    } catch (error) {
      console.error("Error fetching repositories:", error);
      this.setState({
        message: "Failed to fetch repositories. Please try again.",
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  renderRepoTabulator() {
    let openButton = function () {
      let button =
        "<div><button type='button'class='btn btn-outline-secondary btn-sm updatedeletebuttons' >Update</button> <button type='button'class='btn btn-outline-secondary btn-sm updatedeletebuttons' >Delete</button></div>";
      return button;
    };
    console.log(this.state.clients);
    return (
      <div className="managerepo-content">
        <div className="header-container">
          <h4>Manage Repositories</h4>

          {!(this.state.clientId && this.state.clientId > 0) && (
            <div className="client-selection-container">
              <FormControl
                componentClass="select"
                value={this.state.selectedClient}
                onChange={this.handleClientChange}
                className="client-dropdown"
              >
                <option value="">Select a client</option>
                {this.state.clients.map((client) => (
                  <option key={client.clientId} value={client.clientId}>
                    {client.name}
                  </option>
                ))}
              </FormControl>
              <Button
                onClick={this.toggleAddClientForm}
                className="add-client-button"
                aria-label="Add Client"
              >
                <span className="green-plus-sign">{"\u2795"}</span>
              </Button>
            </div>
          )}

          {this.state.showAddClientForm && (
            <div className="add-client-form">
              <FormControl
                type="text"
                value={this.state.newClientName}
                onChange={this.handleNewClientNameChange}
                placeholder="Enter client name"
              />
              <Button onClick={this.saveNewClient}>Save</Button>
              <Button onClick={this.toggleAddClientForm}>Cancel</Button>
            </div>
          )}
        </div>

        <br />
        <ReactTabulator
          id="table"
          style={{
            height: "74%",
            marginLeft: "5px",
          }}
          columns={[
            {
              title: "Repository Owner",
              field: "repositoryOwner",
              editor: "input",
              headerFilter: "input",
            },
            {
              title: "Repository Name",
              field: "folderName",
              editor: "input",
              headerFilter: "input",
            },
            {
              title: "S3 Path",
              field: "s3Path",
              headerFilter: "input",
            },
            {
              title: "",
              headerSort: false,
              formatter: openButton,
              hozAlign: "center",
              cellClick: (e, cell) => {
                this.updateData(e, cell);
              },
            },
          ]}
          ref={(ref) => (this.gridRepoRef = ref)}
          data={this.state.repoData}
          options={{
            pagination: "local",
            paginationSize: 15,
          }}
        />
        <ControlLabel className="errorLabel">{this.state.message}</ControlLabel>
        <div className="addnew-content">
          <form onSubmit={this.addNewRow}>
            <Row>
              <Col sm={3}>
                Repository Owner:
                <FormControl
                  className="input-box"
                  id="repoOwner"
                  type="input"
                  onChange={(e) =>
                    this.setState({ repositoryOwner: e.target.value })
                  }
                  required
                />
              </Col>
              <Col sm={3}>
                Repository Name:
                <FormControl
                  className="input-box"
                  type="input"
                  id="folderName"
                  onChange={(e) =>
                    this.setState({ folderName: e.target.value })
                  }
                  required
                />
              </Col>
              {/* <Col sm={3}>
                S3 Path:
                <FormControl
                  className="input-box"
                  type="input"
                  onChange={(e) => this.setState({ s3Path: e.target.value })}
                />
              </Col> */}
              <Col sm={3}>
                <LoaderButton
                  block
                  bsClass="btn btn-outline-secondary"
                  disabled={this.state.isLoading}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Add New Repository"
                  loadingText="Adding Repository..."
                  className="addNewButton"
                />
              </Col>
            </Row>
          </form>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="managerepo-main">
          <Header props={this.props.props} history={this.props.history} />
          {this.renderRepoTabulator()}
        </div>
      </Fragment>
    );
  }
}

export default ManageRepository;
