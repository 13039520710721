import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function AuthenticatedRoute({
  component: C,
  props: cProps,
  requiredPermission,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        // Check if the user is authenticated
        cProps.props.hasAuthenticated ? (
          // Check for the required permission if it's provided
          requiredPermission ? (
            cProps.props[requiredPermission] ? (
              <C {...props} {...cProps} />
            ) : (
              // Redirect if the user does not have the required permission
              <Redirect to="/no-permission" />
            )
          ) : (
            // If no specific permission is required, just render the component
            <C {...props} {...cProps} />
          )
        ) : (
          // Redirect to login if not authenticated
          <Redirect to="/" />
        )
      }
    />
  );
}
