import React, { Fragment, Component } from "react";
import Header from "../header";
import "./ocrindextemplates.css";
import {
  getOcrTemplatesInfo,
  startOcr,
  getInitializedOcrStatus,
  UpdateAutoOcrItem,
} from "../../libs/API";
import { ReactTabulator } from "react-tabulator";
import { FormControl, Row, Col } from "react-bootstrap";
import LoaderButton from "../LoaderButton";

class OcrIndexTempates extends Component {
  gridOcrTemplateRef = null;
  constructor(props) {
    super(props);
    this.state = {
      indexTemplateOcrData: [],
      templateId: "",
      tableData: [],
      isLoading: false,
    };
  }
  async componentDidMount() {
    await this.loadOcrWithTemplateInfo();
  }

  loadTableData = async (e) => {
    e.preventDefault();
    this.setState({ tableData: [], isLoading: true });
    getInitializedOcrStatus(this.state.templateId)
      .then((data) => {
        console.log(data.data, data, "innerTemplates");
        if (data.data) {
          this.setState({ tableData: data.data, isLoading: false });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  loadOcrWithTemplateInfo = async () => {
    getOcrTemplatesInfo()
      .then((data) => {
        console.log(data.data, data, "template");
        if (data.data) {
          this.setState({ indexTemplateOcrData: data.data });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  PerformOcr = async (e, cell) => {
    e.preventDefault();
    cell.getElement().querySelector("button").disabled = true;
    const templateId = cell.getRow().getData().TemplateId;
    startOcr(templateId)
      .then((data) => {
        console.log(data, "PerformOcr");
        cell.getElement().querySelector("button").disabled = false;
      })
      .catch((error) => {
        console.error(error);
        cell.getElement().querySelector("button").disabled = false;
      });
  };

  handleOcrToggle = async (e, cell) => {
    e.preventDefault();
    const newValue = cell.getRow().getData().AutoOcr; // Get the updated value from row data

    try {
      const templateId = cell.getRow().getData().TemplateId;
      await UpdateAutoOcrItem(templateId, newValue); // Perform the update
    } catch (error) {}
  };

  ocrToggleFormatter = (cell) => {
    const isChecked = cell.getValue();
    const toggle = document.createElement("input");
    toggle.type = "checkbox";
    toggle.checked = isChecked;
    toggle.onchange = (e) => {
      const row = cell.getRow();
      row.update({ AutoOcr: e.target.checked });
    };

    return toggle;
  };

  RefreshButtonOcr = async (e, cell) => {
    e.preventDefault();
    cell.getElement().querySelector("button").disabled = true;
    const templateId = cell.getRow().getData().TemplateId;
    getInitializedOcrStatus(templateId)
      .then((data) => {
        console.log(data, "RefreshButtonOcr");
        if (data.data) {
          this.gridOcrTemplateRef.table.updateData(data.data);
          cell.getElement().querySelector("button").disabled = false;
        }
      })
      .catch((error) => {
        console.error(error);
        cell.getElement().querySelector("button").disabled = false;
      });
  };

  renderOcrStatusTable() {
    let ocrButton = function () {
      let button =
        "<button type='button'class='btn btn-outline-secondary btn-sm updatedeletebtnocr' > OCR </button>";
      return button;
    };

    let refreshButton = function () {
      let button =
        "<button type='button'class='btn btn-outline-secondary btn-sm updatedeletebtnocr' > Refresh </button>";
      return button;
    };

    let updateButton = function () {
      let button =
        "<button type='button'class='btn btn-outline-secondary btn-sm updatedeletebtnocr' > Update </button>";
      return button;
    };

    return (
      <Fragment>
        <div className="mainOcrDiv">
          <form onSubmit={this.loadTableData}>
            <Row>
              <Col sm={4}>
                <FormControl
                  className="customindexTemplateSelect"
                  componentClass="select"
                  onChange={(e) =>
                    this.setState({
                      // templateId:
                      //   e.target.options[
                      //     e.target.options.selectedIndex
                      //   ].getAttribute("data-key"),
                      templateId: e.target.value,
                    })
                  }
                  required
                >
                  <option key={-1} value="">
                    Select Index Template{" "}
                  </option>
                  {this.state.indexTemplateOcrData.map((indexTemplate, i) => (
                    <option key={i} value={indexTemplate.TemplateId}>
                      {indexTemplate.DisplayName}
                    </option>
                  ))}
                </FormControl>
              </Col>
              <Col sm={2}>
                <LoaderButton
                  block
                  bsClass="btn btn-outline-secondary"
                  disabled={this.state.isLoading}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Load Templates"
                  loadingText="Loading..."
                />
              </Col>
            </Row>
          </form>
          <div className="leftdivTemplate">
            <ReactTabulator
              id="templateTable"
              index="TemplateId"
              columns={[
                {
                  title: "Template Name",
                  field: "DisplayName",
                  widthGrow: 2,
                },
                {
                  title: "Repository Owner",
                  field: "RepositoryOwner",
                  widthGrow: 3,
                },
                {
                  title: "Table Name",
                  field: "TableName",
                  visible: false,
                },
                {
                  title: "File Count",
                  field: "filecount",
                  widthGrow: 1,
                },
                {
                  title: "Ocr Count",
                  field: "OcrCompleteCount",
                  widthGrow: 1,
                },
                {
                  title: "Initiated Count",
                  field: "InitialtedCount",
                  widthGrow: 1,
                },
                {
                  title: "Auto OCR",
                  field: "AutoOcr",
                  formatter: this.ocrToggleFormatter,
                  hozAlign: "left",
                  headerSort: false,
                  widthGrow: 1,
                },
                {
                  title: "",
                  headerSort: false,
                  formatter: ocrButton,
                  hozAlign: "center",
                  widthGrow: 1,
                  cellClick: async (e, cell) => {
                    await this.PerformOcr(e, cell);
                  },
                },
                {
                  title: "",
                  headerSort: false,
                  formatter: refreshButton,
                  hozAlign: "center",
                  widthGrow: 1,
                  cellClick: async (e, cell) => {
                    await this.RefreshButtonOcr(e, cell);
                  },
                },
                {
                  title: "",
                  headerSort: false,
                  formatter: updateButton,
                  hozAlign: "center",
                  widthGrow: 1,
                  cellClick: async (e, cell) => {
                    await this.handleOcrToggle(e, cell);
                  },
                },
              ]}
              data={this.state.tableData}
              ref={(ref) => (this.gridOcrTemplateRef = ref)}
              options={{
                pagination: "local",
                paginationSize: 10,
                placeholder:
                  "Select the Index Template and then click Load button",
                layout: "fitColumns", // Ensure columns fit within table width
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="ocr-main">
          <Header props={this.props.props} history={this.props.history} />
          <div className="ocr-content">
            <div>
              <h4> OCR Template Status Table</h4>
              <br />
              {this.renderOcrStatusTable()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default OcrIndexTempates;
