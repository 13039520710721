import React, { Component, Fragment } from "react";
import Popup from "reactjs-popup";
import { Row, Col, FormGroup, Checkbox } from "react-bootstrap";
import LoaderButton from "../LoaderButton";
import { insertOrUpdateUserProfile, getUserProfile } from "../../libs/API";

class FilePropertiesPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      isLoading: false,
      redaction: true,
      pageCount: true,
      fileSize: true,
      fileDimensions: true,
      textScore: true,
      templateColumns: [],
    };

    // console.log("email popup props", props);
  }

  async componentDidMount() {
    await this.checkUserProfile();
    this.setState({ popupOpen: this.props.props.showFilePropertiesPopup });
  }

  checkUserProfile = async () => {
    const userProfile = {
      userId: this.props.props.userId,
      templateName: this.props.props.templateName,
    };
    await getUserProfile(userProfile).then((x) => {
      if (x.data !== "" && x.status === 200) {
        this.setState({
          redaction: x.data.redaction,
          fileSize: x.data.fileSize,
          pageCount: x.data.pageCount,
          fileDimensions: x.data.pageDimensions,
          textScore: x.data.textScore,
          templateColumns: x.data.templateColumnsToShow,
        });
      }
    });
  };

  handleRedactionChange = (event) => {
    this.setState({ redaction: event.target.checked });
  };

  handlePageCountChange = (event) => {
    this.setState({ pageCount: event.target.checked });
  };

  handlefileSizeChange = (event) => {
    this.setState({ fileSize: event.target.checked });
  };

  handlefileDimensionsChange = (event) => {
    this.setState({ fileDimensions: event.target.checked });
  };

  handletextScoreChange = (event) => {
    this.setState({ textScore: event.target.checked });
  };

  handleSaveUserProfile = async (e) => {
    e.preventDefault();
    const checkboxDiv = document.getElementById("checkboxDiv");
    const checkboxes = Array.from(
      checkboxDiv.querySelectorAll('input[type="checkbox"]')
    );

    const TemplateColumnsToShow = [];
    checkboxes.forEach((checkbox) => {
      if (!checkbox.checked) {
        TemplateColumnsToShow.push(checkbox.name);
      }
    });
    const userProfile = {
      fileSize: this.state.fileSize,
      pageCount: this.state.pageCount,
      pageDimensions: this.state.fileDimensions,
      textScore: this.state.textScore,
      redaction: this.state.redaction,
      templateColumnsToShow: TemplateColumnsToShow,
      userId: this.props.props.userId,
      templateName: this.props.props.templateName,
    };
    console.log(userProfile);
    await insertOrUpdateUserProfile(userProfile).then((x) => {
      console.log(x);
      if (x.status === 200) {
        this.closePopup();
      } else {
      }
    });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
    this.props.unmountMe();
  };

  render() {
    return (
      <Fragment>
        <Popup
          contentStyle={{
            width: "32%",
            borderRadius: "15px",
            padding: "15px",
          }}
          open={this.state.popupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closePopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            <div>
              <h3>Hide/Show Columns</h3>
              <form onSubmit={this.handleSaveUserProfile}>
                <Row>
                  <Col sm={6}>
                    <h5>Active Template Columns</h5>
                    <div id="checkboxDiv">
                      {this.props.props.columns.map((item, index) => {
                        let isChecked = !this.state.templateColumns.includes(
                          index.toString()
                        );
                        return (
                          <div key={index}>
                            <FormGroup key={index}>
                              <Row key={index}>
                                <Col sm={12}>
                                  <Checkbox
                                    key={index}
                                    name={index}
                                    value={item.InputName}
                                    defaultChecked={isChecked}
                                  >
                                    {""} {item.InputName}
                                  </Checkbox>
                                </Col>
                              </Row>
                            </FormGroup>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                  <Col sm={6}>
                    {this.props.props.hasFileProperties && (
                      <div>
                        <h5>File Properties</h5>

                        <FormGroup>
                          <Row>
                            <Col sm={12}>
                              <Checkbox
                                key="pageCount"
                                value="pageCount"
                                name="pageCountColumn"
                                onChange={this.handlePageCountChange}
                                checked={this.state.pageCount}
                              >
                                {""} Page Count
                              </Checkbox>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Checkbox
                                key="fileSize"
                                value="fileSize"
                                name="fileSizeColumn"
                                onChange={this.handlefileSizeChange}
                                checked={this.state.fileSize}
                              >
                                {""} File Size
                              </Checkbox>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Checkbox
                                key="fileDimensions"
                                value="fileDimensions"
                                name="fileDimensionsColumn"
                                onChange={this.handlefileDimensionsChange}
                                checked={this.state.fileDimensions}
                              >
                                {""} Page Dimensions
                              </Checkbox>
                            </Col>
                          </Row>
                        </FormGroup>
                      </div>
                    )}
                    {this.props.props.hasManageRedaction ||
                    this.props.props.hasTextScore ? (
                      <div>
                        <h5>Additional Columns</h5>
                        {this.props.props.hasManageRedaction && (
                          <Row>
                            <Col sm={12}>
                              <Checkbox
                                key="redaction"
                                value="redaction"
                                name="redactionColumn"
                                onChange={this.handleRedactionChange}
                                checked={this.state.redaction}
                              >
                                {""} Redaction
                              </Checkbox>
                            </Col>
                          </Row>
                        )}
                        {this.props.props.hasTextScore && (
                          <Row>
                            <Col sm={12}>
                              <Checkbox
                                key="textScore"
                                value="textScore"
                                name="textScoreColumn"
                                onChange={this.handletextScoreChange}
                                checked={this.state.textScore}
                              >
                                {""} Text Score
                              </Checkbox>
                            </Col>
                          </Row>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                  <FormGroup>
                    <Row
                      style={{
                        marginLeft: "2px",
                      }}
                    >
                      <Col sm={12}>
                        <LoaderButton
                          block
                          bsClass="btn btn-outline-secondary"
                          disabled={this.state.isLoading}
                          type="submit"
                          isLoading={this.state.isLoading}
                          text="Save Preferences"
                          loadingText="Saving......"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Row>
              </form>
            </div>
          </div>
        </Popup>
      </Fragment>
    );
  }
}

export default FilePropertiesPopup;
