import React, { Component } from "react";
import Dropzone from "react-dropzone";
import {
  uploadFile,
  uploadFileChunk,
  uploadFileComplete,
} from "../../libs/API";
import LoaderButton from "../LoaderButton";
import { v4 as uuidv4 } from "uuid";

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: [],
      progressInfos: [],
      message: [],
      fileInfos: [],
      isLoading: false,
      isDisabled: true,
      error: "",
    };
    // console.log("upload.js", props);
  }

  componentDidMount() {}

  uploadFileData = async (file, count, fileCount) => {
    this.setState({ isLoading: true });
    const fileSplit = file.name.split(".");
    const bytes = Array.from(Buffer.from(await this.getFileBytes(file)));
    let addFileParams = {
      uploadedFile: bytes,
      fileName: fileSplit[0],
      tableName: this.props.tableName,
      nodeId: this.props.nodeId,
      extension: fileSplit[1],
    };

    uploadFile(addFileParams)
      .then((response) => {
        if (response) {
          let newTabulatorRow = {
            FileName: fileSplit[0],
            FileId: response,
            HasAnnotations: false,
            FileExtension: fileSplit[1],
          };
          this.props.addNewTabulatorRow(newTabulatorRow);
          this.setState((prev) => {
            let nextMessage = [
              ...prev.message,
              "Uploaded the file successfully: " + fileSplit[0],
            ];
            if (count === fileCount) {
              return {
                message: nextMessage,
                selectedFiles: [],
                isLoading: false,
                isDisabled: true,
              };
            } else {
              return {
                message: nextMessage,
              };
            }
          });
        }
      })

      .catch(() => {
        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Uploaded the file successfully: " + fileSplit[0],
          ];
          return {
            message: nextMessage,
            isLoading: false,
          };
        });
      });
  };

  getFileBytes = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  uploadFiles = (e) => {
    e.preventDefault();
    this.setState({
      error: "",
    });
    const selectedFiles = this.state.selectedFiles;
    if (this.props.tableName.length <= 0) {
      this.setState({
        error: "Select The Index Template",
      });
      return;
    }
    if (selectedFiles.length <= 0) {
      this.setState({
        error: "Select the file",
      });
      return;
    }
    this.setState(
      {
        message: [],
      },
      () => {
        const partSize = 1048576 * 2;
        for (let i = 0; i < selectedFiles.length; i++) {
          //this.uploadFileData(selectedFiles[i], i, selectedFiles.length - 1);
          this.processFile(
            selectedFiles[i],
            partSize,
            selectedFiles.length - 1,
            i
          );
        }
      }
    );
  };

  uploadFileForAppendPrepend = (e) => {
    e.preventDefault();
    this.setState({
      error: "",
    });
    const selectedFiles = this.state.selectedFiles;
    if (this.props.appendPrependValue.length <= 0) {
      this.setState({
        error: "Select the append prepend option",
      });
      return;
    }
    if (this.props.appendPrependValue === "Insert") {
      if (this.props.insertPageAfter <= 0) {
        this.setState({
          error: "Page number required",
        });
        return;
      }
      if (this.props.insertPageAfter > this.props.totalPageCount) {
        this.setState({
          error: "Page number entered is more than total page count",
        });
        return;
      }
    }
    if (selectedFiles.length <= 0) {
      this.setState({
        error: "Select the file",
      });
      return;
    }
    if (selectedFiles.length > 1) {
      this.setState({
        error: "You can Append or Prepend 1 file at a time.",
      });
      return;
    }
    this.setState(
      {
        message: [],
      },
      () => {
        const partSize = 1048576 * 2;
        for (let i = 0; i < selectedFiles.length; i++) {
          //this.uploadFileData(selectedFiles[i], i, selectedFiles.length - 1);
          this.processFile(
            selectedFiles[i],
            partSize,
            selectedFiles.length - 1,
            i
          );
        }
      }
    );
  };

  onDrop = (files) => {
    if (files.length > 0) {
      this.setState({ selectedFiles: files, isDisabled: false });
    }
  };

  processFile = async (file, chunkSize, fileCount, iterationCount) => {
    this.setState({ isLoading: true });
    const totalCount =
      file.size % chunkSize === 0
        ? file.size / chunkSize
        : Math.floor(file.size / chunkSize) + 1;

    let start = 0;
    let end = chunkSize;
    let count = 1;
    let chunkFileName = uuidv4() + "." + file.name.split(".").pop();
    this.setState({ originalName: file.name });
    while (count <= totalCount) {
      let chunk = file.slice(start, end);
      await this.uploadChunk(
        chunk,
        count,
        chunkFileName,
        totalCount,
        file.name,
        fileCount,
        iterationCount
      );
      count++;
      start = end;
      end = end + chunkSize;
    }
  };

  uploadChunk = async (
    chunk,
    count,
    chunkFileName,
    totalCount,
    originalFileName,
    fileCount,
    iterationCount
  ) => {
    let addFileParams = {
      chunk: chunk,
      chunkFileName: chunkFileName,
      count: count,
    };
    await uploadFileChunk(addFileParams).then(async (response) => {
      try {
        const data = response.data;
        if (data.data === -1) {
          alert(response.errorMessage);
          return;
        }
        if (data.isSuccess && data.data >= 0) {
          if (data.data == totalCount) {
            let uploadParams = {
              chunkFileName: chunkFileName,
              originalFileName: originalFileName,
              tableName: this.props.tableName,
              nodeId: this.props.nodeId,
              appendPrependOperation: this.props.appendPrependValue,
              appendPrependFileId: this.props.appendPrependFileId,
              insertPageAfter: this.props.insertPageAfter,
            };
            await uploadFileComplete(uploadParams).then((response) => {
              console.log(response);
              if (response.data.data !== "BadRequest") {
                let fileSplit = originalFileName.split(".");
                if (!this.props.prependAppendCall) {
                  let newTabulatorRow = {
                    FileName: fileSplit[0],
                    FileId: response.data.data,
                    HasAnnotations: false,
                    FileExtension: fileSplit[1],
                  };
                  this.props.addNewTabulatorRow(newTabulatorRow);
                }
                if (!this.props.prependAppendCall) {
                  this.setState((prev) => {
                    let nextMessage = [
                      ...prev.message,
                      "Uploaded the file successfully: " + fileSplit[0],
                    ];
                    if (iterationCount === fileCount) {
                      return {
                        message: nextMessage,
                        selectedFiles: [],
                        isLoading: false,
                        isDisabled: true,
                      };
                    } else {
                      return {
                        message: nextMessage,
                      };
                    }
                  });
                } else {
                  this.setState((prev) => {
                    let nextMessage = [
                      ...prev.message,
                      `the file has successfully: ${this.props.appendPrependValue}`,
                    ];
                    if (iterationCount === fileCount) {
                      return {
                        message: nextMessage,
                        selectedFiles: [],
                        isLoading: false,
                        isDisabled: true,
                      };
                    } else {
                      return {
                        message: nextMessage,
                      };
                    }
                  });
                }
              } else {
                this.setState((prev) => {
                  let nextMessage = [...prev.message, `Error Contact Admin`];
                  return {
                    message: nextMessage,
                    selectedFiles: [],
                    isLoading: false,
                    isDisabled: true,
                  };
                });
              }
            });
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    });
  };

  // uploadCompleted = async (fileGuid) => {
  //   let url = `${Config.ImageSyncAPI}/api/Chunks/UploadComplete`;
  //   var formData = new FormData();
  //   formData.append("fileName", fileGuid);
  //   const response = await axios.post(
  //     url,
  //     {},
  //     {
  //       params: {
  //         fileName: fileGuid,
  //         originalFileName: this.state.originalName,
  //       },
  //       data: formData,
  //     }
  //   );
  //   const data = response.data;
  //   if (data.isSuccess) {
  //     this.setState({ progress: 100 });
  //     const message = "Upload completed";
  //     this.setState({ message: message, enableAlert: true, success: true });
  //   } else {
  //     const message = "Upload Error";
  //     this.setState({ progress: 0, message: message, enableAlert: true });
  //   }
  // };

  render() {
    const { selectedFiles, message, fileInfos } = this.state;
    return (
      <div>
        <div className="my-3">
          <Dropzone onDrop={this.onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  {selectedFiles &&
                  Array.isArray(selectedFiles) &&
                  selectedFiles.length ? (
                    <div className="selected-file">
                      {selectedFiles.length > 3
                        ? `${selectedFiles.length} files`
                        : selectedFiles.map((file) => file.name).join(", ")}
                    </div>
                  ) : (
                    `Drag and drop files here, or click to select files`
                  )}
                </div>
                <aside className="selected-file-wrapper">
                  <LoaderButton
                    block
                    bsClass="btn btn-outline-secondary"
                    disabled={this.state.isDisabled}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text={
                      this.props.prependAppendCall ? "Insert" : "Upload File"
                    }
                    loadingText={
                      this.props.prependAppendCall
                        ? "Inserting File......"
                        : "Uploading File......"
                    }
                    onClick={
                      this.props.prependAppendCall
                        ? this.uploadFileForAppendPrepend
                        : this.uploadFiles
                    }
                  />
                  {/* <button
                    className="btn btn-success"
                    disabled={!selectedFiles}
                    onClick={this.uploadFiles}
                  >
                    Upload
                  </button> */}
                </aside>
              </section>
            )}
          </Dropzone>
        </div>
        {this.state.error.length > 0 && (
          <div className="alert alert-secondary" role="alert">
            {this.state.error}
          </div>
        )}
        {message.length > 0 && (
          <div className="alert alert-secondary" role="alert">
            <ul>
              {message.map((item, i) => {
                return <li key={i}>{item}</li>;
              })}
            </ul>
          </div>
        )}
        {fileInfos.length > 0 && (
          <div className="card">
            <div className="card-header">List of Files</div>
            <ul className="list-group list-group-flush">
              {fileInfos &&
                fileInfos.map((file, index) => (
                  <li className="list-group-item" key={index}>
                    <a href={file.url}>{file.name}</a>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default Upload;
