import React, { Component, Fragment } from "react";
import "../Browse/browse.css";
import { Button, Row, Col, FormGroup, FormControl } from "react-bootstrap";
import Header from "../../Component/header";
import Tree from "@naisutech/react-tree";
import BrowseGridView from "./gridViewFile";
import BrowserImage from "./browseFolderImage";
import Split from "react-split";
import EmailPopup from "../emailpopup";
import AddFolderPopup from "./addfolderpopup";
import MoveFilesToFolderPopup from "./moveAndCopyFilesToFolderPopup";
import ExpungementPopup from "../expungementpopup";
import Popup from "reactjs-popup";
import MoveCopyFileSvg from "./moveCopyFileSvg";
import FileProperties from "../Search/filePropertiesPopup";
import FilePropertiesImage from "../Images/FileProperties.svg";
import {
  DeleteFileSvg,
  RenameFileSvg,
  EmailSvgImage,
  RenameFileSaveSvg,
  RenameFileCancelSvg,
  ExpungementSvg,
  UploadSvgImage,
  DeleteFolderSvg,
  AddFolderSvg,
  SearchSvgImage,
} from "../Svg/allsvg";
import {
  getRepos,
  getInnerNodes,
  updateFileName,
  getFileData,
  deleteFolder,
  deleteFile,
  getTemplatesBasedOnRepositoryId,
  getFileLIst,
  getUserProfile,
  getUserProfileWithOutTemplate,
} from "../../libs/API";
import UploadFilesPopup from "./uploadfilespopup";
import AppendPrependPdf from "./appenPrependPdf";

class Browse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      repos: [],
      innerNodes: [],
      folderArray: [],
      fileObject: [],
      showEmailPopup: false,
      fileIdArray: [],
      fileName: "",
      selectedNode: 0,
      showExpungementPopup: false,
      showAddFolderPopup: false,
      showUploadFilesPopup: false,
      popupOpen: false,
      client: "",
      deleteFilePopupOpen: false,
      mainNode: 0,
      indexTemplateData: [],
      columns: [],
      disabled: true,
      showMoveFileToFolderPopup: false,
      isCopy: false,
      copyMoveSelectedNode: null,
      showAppendPrependPopup: false,
      showFilePropertiesPopup: false,
      fileSize: true,
      pageCount: true,
      pageDimensions: true,
      redactionColumn: true,
      selectedTemplate: "",
      templateColumnsToShow: [],
      filePropertiesButton: false,
    };
    this.LoadRepository = this.LoadRepository.bind(this);
    // console.log("Browse-Props", props);
  }

  async componentDidMount() {
    await this.LoadRepository();
    let clientName = "";
    if (this.props.props.hasCliftonWorkflow) {
      clientName = "clifton";
    } else if (this.props.props.hasMedfordWorkflow) {
      clientName = "medford";
    } else if (this.props.props.hasEnglewoodWorkflow) {
      clientName = "englewood";
    } else if (this.props.props.hasGlenrockWorkflow) {
      clientName = "glenrock";
    } else if (this.props.props.hasAberdeenWorkflow) {
      clientName = "Aberdeen";
    } else if (this.props.props.hasDemoExpungementWorkflow) {
      clientName = "Demo";
    } else if (this.props.props.hasEastamptonExpungementWorkflow) {
      clientName = "Eastampton";
    }
    this.setState({ client: clientName });
  }

  async LoadRepository() {
    await getRepos().then((data) => {
      this.setState({ repos: data });
      let jsonData = this.transformData();
      this.setState({ folderArray: jsonData });
    });
  }

  pushFiles = (nodeId, files) => {
    let data = this.state.folderArray;
    let parentNode = data.find((x) => x.id === nodeId);
    if (parentNode && files) {
      let tabData = [];
      // eslint-disable-next-line array-callback-return
      files.map((x) => {
        tabData.push({
          FileName:
            x.FileName === "No Files for this Folder" ? null : x.FileName,
          FileId: x.FileId,
          HasAnnotations:
            x.FileName !== "No Files for this Folder"
              ? x.HasAnnotations == null
                ? false
                : x.HasAnnotations
              : null,
          PageCount:
            x.FileName !== "No Files for this Folder"
              ? x.PageCount == null
                ? ""
                : x.PageCount
              : null,
          FileSize:
            x.FileName !== "No Files for this Folder"
              ? x.FileSize == null
                ? ""
                : x.FileSize
              : null,
          PageDimensions:
            x.FileName !== "No Files for this Folder"
              ? x.PageDimensions == null
                ? ""
                : x.PageDimensions
              : null,
          FileExtension:
            x.FileExtension === "No Files for this Folder"
              ? null
              : x.FileExtension,
        });
      });
      tabData.forEach((eachObj) => {
        if (eachObj.FileId === 0) {
          tabData = [];
        }
      });
      this.setState({ fileObject: tabData });
    }
  };

  async GetInnerNodes(nodeId, count) {
    await getInnerNodes(nodeId, count).then((data) => {
      let json = this.state.innerNodes;
      for (let i = 0; i < data.DataTable.length; i++) {
        if (
          json.findIndex((x) => x.nodeId === data.DataTable[i].NodeId) === -1
        ) {
          json.push(data.DataTable[i]);
        }
      }
      let jsonData = this.state.folderArray;
      this.transformInnerChild(nodeId).then((e) => {
        for (let i = 0; i < e.length; i++) {
          if (jsonData.findIndex((x) => x.id === e[i].id) === -1) {
            jsonData.push(e[i]);
          }
        }
        if (data && data.FilesTable !== null && data.FilesTable.length >= 1) {
          this.pushFiles(nodeId, data.FilesTable);
        }
        this.setState({ folderArray: jsonData });
      });
    });
  }

  loadTemplates = async (mainNode) => {
    await getTemplatesBasedOnRepositoryId(mainNode).then((data) => {
      if (data) {
        this.setState({ indexTemplateData: data, disabled: false }, () => {
          const templateSelect = document.getElementById("indexTemplates");
          if (data.length === 1 && data[0].tableName !== "-1") {
            templateSelect.options[0].style.display = "none";
            templateSelect.selectedIndex = "1";
          } else {
            templateSelect.options[0].style.display = "block";
            templateSelect.selectedIndex = "0";
          }
          templateSelect.dispatchEvent(new Event("change", { bubbles: true }));
        });
      }
    });
  };

  checkUserProfile = async (tableName) => {
    const userProfile = {
      userId: this.props.props.userId,
      templateName: tableName,
    };
    await getUserProfile(userProfile).then(async (x) => {
      if (x.data !== "") {
        this.setState({
          templateColumnsToShow: x.data.templateColumnsToShow,
          redactionColumn: x.data.redaction,
          fileSize: x.data.fileSize,
          pageDimensions: x.data.pageDimensions,
          pageCount: x.data.pageCount,
        });
      }
    });
  };

  checkUserProfileWithOutTemplate = async (mainNode) => {
    const userProfile = {
      userId: this.props.props.userId,
      repoId: mainNode,
    };
    await getUserProfileWithOutTemplate(userProfile).then(async (x) => {
      console.log(x, "x");
      if (x.data !== "") {
        this.setState({
          redactionColumn: x.data.redaction,
          fileSize: x.data.fileSize,
          pageDimensions: x.data.pageDimensions,
          pageCount: x.data.pageCount,
        });
      } else {
        this.setState({
          templateColumnsToShow: [],
          redactionColumn: true,
          fileSize: true,
          pageDimensions: true,
          pageCount: true,
        });
      }
    });
  };

  handleIndexTemplateChange = async (e) => {
    e.preventDefault();
    let tableName = e.target.value;
    this.child.gridBrowseRef.table.setColumns([]);
    this.setState({
      fileObject: [],
      selectedTemplate: tableName,
      filePropertiesButton: false,
    });
    if (tableName !== "-1") {
      await this.checkUserProfile(tableName);
      await getFileLIst(this.state.selectedNode, tableName).then((data) => {
        let results = JSON.parse(data[1]);
        results.forEach((results) => {
          if (results.HasAnnotations == null) {
            results.HasAnnotations = false;
          }
        });
        if (results.length > 0) {
          this.setState({
            fileObject: results,
            columns: data[0],
            filePropertiesButton: true,
          });
          //let node = this.generatePath(this.state.selectedNode, "");
          let newColumns = this.child.handleFilePath();
          this.child.gridBrowseRef.table.setColumns(newColumns);
        }
      });
    } else {
      await this.GetInnerNodes(this.state.selectedNode, 2);
      let newColumns = this.child.handleIndexTemplateColumns();
      this.child.gridBrowseRef.table.setColumns(newColumns);
    }
  };

  handleSearch = () => {
    this.props.history.push("/search");
  };

  onSelect = async (selectedNode) => {
    let nodeId = selectedNode.id;
    let node = this.generatePath(nodeId, "");
    this.setState({
      selectedNode: nodeId,
      copyMoveSelectedNode: selectedNode,
      fileName: node,
    });
    let data = this.state.folderArray;
    let mainNode = this.getRoot(data, selectedNode);
    if (mainNode) {
      this.setState({ mainNode: mainNode });
    }
    await this.loadTemplates(mainNode);
    await this.GetInnerNodes(nodeId, this.state.indexTemplateData.length);
    if (
      this.state.indexTemplateData.length > 1 ||
      this.state.indexTemplateData.length === 0
    ) {
      let newColumns = this.child.handleIndexTemplateColumns();
      this.child.gridBrowseRef.table.setColumns(newColumns);
    }
  };

  getRoot = (data, selectedNode) => {
    let parentId = selectedNode.parentId;
    if (parentId === null) {
      this.checkUserProfileWithOutTemplate(selectedNode.repoId);
      return selectedNode.repoId;
    }
    var element = data.find((x) => x.id === parentId);
    if (element && element.parentId !== null) {
      return this.getRoot(data, element);
    }
    return element.repoId;
  };

  generatePath = (nodeId, nodeName) => {
    let data = this.state.folderArray;
    let node = data.find((x) => x.id === nodeId);
    if (node) {
      if (node.parentId != null) {
        return this.generatePath(node.parentId, node.label + "/" + nodeName);
      } else {
        return node.label + "/" + nodeName;
      }
    }
  };

  transformInnerChild = (parentId) => {
    return new Promise((y) => {
      let data = this.state.innerNodes;
      let maps = data.map(function (x) {
        return {
          parentId: parentId,
          id: x.NodeId,
          label: x.FolderName,
          items: [],
        };
      });
      return y(maps);
    });
  };

  transformData = () => {
    let data = this.state.repos;
    return data.map(function (x) {
      return {
        parentId: null,
        id: x.nodeId,
        label: x.folderName,
        items: [],
        repoId: x.repositoryId,
      };
    });
  };

  handlePopup = () => {
    let rows = this.child.gridBrowseRef.table.getSelectedData();
    if (rows.length > 0) {
      let fileIds = rows.map(function (m) {
        return {
          fileId: m.FileId,
          redaction: m.HasAnnotations,
          fileName: m.FileName,
        };
      });
      this.setState({ showEmailPopup: true, fileIdArray: fileIds });
    }
  };

  addNewTabulatorRow = (addNewRow) => {
    //this.child.gridBrowseRef.table.addRow(addNewRow, true);
    let fileList = this.state.fileObject;
    fileList.unshift(addNewRow);
    this.setState({ fileObject: fileList });
  };

  hideTooTip = (hideParams) => {
    document.getElementsByClassName(hideParams)[0].style.display = "none";
  };

  showToolTip = (showParams) => {
    document.getElementsByClassName(showParams)[0].style.display = "block";
  };

  disableBrowseFolderClick = () => {
    document.getElementsByClassName("cJYPLF")[0].style.pointerEvents = "none";
  };

  enableBrowseFolderClick = () => {
    document.getElementsByClassName("cJYPLF")[0].style.pointerEvents = "all";
  };

  handleRenameFile = () => {
    let selectedRows = this.child.gridBrowseRef.table.getSelectedData();
    if (selectedRows.length > 0) {
      let hideParams = "emailSelectToolTip";
      let showParams = "renameFileToolTip";
      this.hideTooTip(hideParams);
      this.showToolTip(showParams);
      let newColumns = [];
      const templateSelect = document.getElementById("indexTemplates");
      if (
        this.state.indexTemplateData.length > 1 ||
        templateSelect.selectedIndex === "0"
      ) {
        newColumns = this.child.handleIndexTemplateColumns(true);
        this.child.gridBrowseRef.table.setColumns(newColumns);
      } else {
        newColumns = this.child.handleFilePath(true);
        this.child.gridBrowseRef.table.setColumns(newColumns);
      }
      this.child.gridBrowseRef.table.setData(selectedRows);
      this.child.setGridFooterText(1, selectedRows.length);
      this.disableBrowseFolderClick();
    }
  };

  handleRenameFileCancel = (cancelFile) => {
    let showParams = "emailSelectToolTip";
    let hideParams = "renameFileToolTip";
    if (cancelFile === false) {
      let editedCells = this.child.gridBrowseRef.table.getEditedCells();
      editedCells.forEach(function (cell) {
        let oldVal = cell.getOldValue();
        if (oldVal !== null) {
          try {
            cell.restoreOldValue();
          } catch {}
        }
      });
    }
    let newColumns = [];
    const templateSelect = document.getElementById("indexTemplates");
    if (
      this.state.indexTemplateData.length > 1 ||
      templateSelect.selectedIndex === "0"
    ) {
      newColumns = this.child.handleIndexTemplateColumns();
      this.child.gridBrowseRef.table.setColumns(newColumns);
    } else {
      newColumns = this.child.handleFilePath();
      this.child.gridBrowseRef.table.setColumns(newColumns);
    }
    this.child.gridBrowseRef.table.setData(this.state.fileObject);
    this.child.setGridFooterText(1);
    this.hideTooTip(hideParams);
    this.showToolTip(showParams);
    this.enableBrowseFolderClick();
  };

  handleRenameFileSave = async () => {
    let editedCells = this.child.gridBrowseRef.table.getEditedCells();
    let mappedCells = [];
    if (editedCells.length > 0) {
      for (let i = 0; i < editedCells.length; i++) {
        let currentValue = editedCells[i].getValue().trim();
        let updateFileNameParams = {
          fileId: editedCells[i].getRow().getData().FileId,
          newfileName: currentValue,
        };
        mappedCells.push(updateFileNameParams);
      }
      await this.updateFileName(mappedCells);
    }
    this.enableBrowseFolderClick();
  };

  updateFileName = async (renameFilesParams) => {
    updateFileName(renameFilesParams).then((data) => {
      if (data.status === 200) {
        this.handleRenameFileCancel(true);
      }
    });
  };

  handleChildUnmount = () => {
    this.setState({
      showEmailPopup: false,
      showExpungementPopup: false,
      showAddFolderPopup: false,
      showUploadFilesPopup: false,
      showMoveFileToFolderPopup: false,
      showAppendPrependPopup: false,
      showFilePropertiesPopup: false,
    });
  };

  handleRemoveFilesAfterMove = (fileId) => {
    this.child.gridBrowseRef.table.deleteRow(fileId);
  };

  addNewTreeFolder = (e) => {
    let treeData = this.state.folderArray;
    let index = treeData.findIndex((x) => x.id === e.id);
    if (index <= 0) {
      treeData.push(e);
    }
    this.setState({ folderArray: treeData });
  };

  handleExpungementPopup = async (e) => {
    e.preventDefault();
    const rows = this.child.gridBrowseRef.table.getSelectedData();
    // console.log(rows);
    if (this.state.selectedTemplate != -1) {
      if (rows.length === 1) {
        const FileId = rows[0].FileId;
        if (
          this.state.client === "Aberdeen" ||
          this.state.client === "Demo" ||
          this.state.client === "Eastampton"
        ) {
          this.setState({ showExpungementPopup: true, fileIdArray: rows });
        } else {
          getFileData(FileId, this.state.client).then((data) => {
            if (data) {
              data[0].FileId = FileId;
              this.setState({ showExpungementPopup: true, fileIdArray: data });
            }
          });
        }
      } else if (rows.length > 1) {
        alert("You can expunge 1 file at a time.");
      }
    } else {
      alert("Please Select Index Template.");
    }
  };

  handleAddFolderPopup = async (e) => {
    e.preventDefault();
    if (this.state.selectedNode > 0) {
      this.setState({ showAddFolderPopup: true });
    }
  };

  handleMoveCopyFilePopup = async (e) => {
    const rows = this.child.gridBrowseRef.table.getSelectedData();
    let isCopy = e.currentTarget.id === "moveButton" ? false : true;
    if (rows.length >= 1) {
      let fileIds = rows.map(function (m) {
        return {
          fileId: m.FileId,
          fileName: m.FileName,
        };
      });
      this.setState({
        showMoveFileToFolderPopup: true,
        isCopy: isCopy,
        fileIdArray: fileIds,
      });
    }
  };

  handleUploadFiles = async (e) => {
    e.preventDefault();
    const rows = this.child.gridBrowseRef.table.getSelectedData();
    if (rows.length > 1) {
      alert("You can Append or Prepend 1 file at a time.");
      return;
    }
    if (rows.length === 1) {
      this.setState({ showAppendPrependPopup: true, fileIdArray: rows });
      return;
    }
    if (this.state.selectedNode > 0) {
      this.setState({ showUploadFilesPopup: true });
      return;
    }
  };

  handleDeleteFolderPopup = async (e) => {
    e.preventDefault();
    let folderData = this.state.folderArray;
    let node = folderData.find((x) => x.id === this.state.selectedNode);
    if (node) {
      if (node.parentId != null) {
        deleteFolder(node.id).then((data) => {
          if (data.status === 200) {
            this.setState({ folderArray: [] });
            let filteredData = folderData.filter((x) => x.parentId == null);
            this.setState({
              folderArray: filteredData,
              innerNodes: [],
              selectedNode: node.parentId,
              popupOpen: false,
              fileObject: [],
              fileName: "",
            });
            //let node1 = this.generatePath(node.parentId, "");
            // let newColumns = this.child.handleFilePath();
            //this.child.gridBrowseRef.table.setColumns(newColumns);
            this.child.gridBrowseRef.table.setColumns([]);
            this.closePopup();
          }
        });
      }
    }
  };

  handleDeleteFile = (e) => {
    e.preventDefault();
    const rows = this.child.gridBrowseRef.table.getSelectedData();
    if (rows.length >= 1) {
      let fileIds = [];
      for (let i = 0; i < rows.length; i++) {
        let Params = {
          fileId: rows[i].FileId,
        };
        fileIds.push(Params);
      }
      deleteFile(fileIds).then((data) => {
        if (data.status === 200) {
          let fileList = this.state.fileObject;
          // for (var i = 0; i < rows.length; i++) {
          //   this.child.gridBrowseRef.table.deleteRow(rows[i]);
          //   console.log("singh", rows[i].FileId);
          // }
          let filteredList = fileList.filter((x) => {
            return !fileIds.some(
              (y) => parseInt(y.fileId) === parseInt(x.FileId)
            );
          });
          this.setState({ fileObject: filteredList });
          this.closePopup();
        }
      });
    }
  };

  closePopup = () => {
    this.setState({
      popupOpen: false,
      deleteFilePopupOpen: false,
      showFilePropertiesPopup: false,
    });
  };

  handleFilePropertiesPopup = () => {
    this.setState({ showFilePropertiesPopup: true });
  };

  openDeleteFolderPopup = (e) => {
    e.preventDefault();
    let folderData = this.state.folderArray;
    let node = folderData.find((x) => x.id === this.state.selectedNode);
    if (node) {
      if (node.parentId != null) {
        this.setState({ popupOpen: true });
      }
    }
  };

  openDeleteFilePopup = (e) => {
    e.preventDefault();
    const rows = this.child.gridBrowseRef.table.getSelectedData();
    if (rows.length >= 1) {
      this.setState({ deleteFilePopupOpen: true });
    }
  };

  renderAppendPrependFilePopup() {
    return (
      <Fragment>
        {this.state.showAppendPrependPopup && (
          <AppendPrependPdf
            props={{
              filePath: this.state.fileName,
              fileIdArray: this.state.fileIdArray,
              showAppendPrependPopup: this.state.showAppendPrependPopup,
            }}
            unmountMe={this.handleChildUnmount}
          />
        )}
      </Fragment>
    );
  }

  renderEmailPop() {
    return (
      <Fragment>
        {this.state.showEmailPopup && (
          <EmailPopup
            props={{
              showEmailPopup: this.state.showEmailPopup,
              fileIdArray: this.state.fileIdArray,
              manageRedaction: this.props.props.hasManageRedaction,
            }}
            unmountMe={this.handleChildUnmount}
          />
        )}
      </Fragment>
    );
  }

  renderAddFolderPop() {
    return (
      <Fragment>
        {this.state.showAddFolderPopup && (
          <AddFolderPopup
            props={{
              parentNodeId: this.state.selectedNode,
              showAddFolderPopup: this.state.showAddFolderPopup,
              filePath: this.state.fileName,
            }}
            unmountMe={this.handleChildUnmount}
            addFolder={this.addNewTreeFolder}
          />
        )}
      </Fragment>
    );
  }

  renderMoveFilesToFolderPopup() {
    return (
      <Fragment>
        {this.state.showMoveFileToFolderPopup && (
          <MoveFilesToFolderPopup
            props={{
              showMoveFileToFolderPopup: this.state.showMoveFileToFolderPopup,
            }}
            selectedNode={this.state.copyMoveSelectedNode}
            Data={this.state.folderArray}
            getRoot={this.getRoot}
            unmountMe={this.handleChildUnmount}
            mainFilePath={this.state.fileName}
            fileIdArray={this.state.fileIdArray}
            isCopy={this.state.isCopy}
            handleRemoveFilesAfterMove={this.handleRemoveFilesAfterMove}
            addNewTabulatorRow={this.addNewTabulatorRow}
          />
        )}
      </Fragment>
    );
  }
  renderFilePropertiesPopup() {
    return (
      <Fragment>
        {this.state.showFilePropertiesPopup && (
          <FileProperties
            props={{
              showFilePropertiesPopup: this.state.showFilePropertiesPopup,
              columns: this.state.columns,
              userId: this.props.props.userId,
              templateName: this.state.selectedTemplate,
              hasManageRedaction: this.props.props.hasManageRedaction,
              hasTextScore: false,
              hasFileProperties: this.props.props.hasFileProperties,
            }}
            unmountMe={this.handleChildUnmount}
          />
        )}
      </Fragment>
    );
  }

  renderUploadFilesPop() {
    return (
      <Fragment>
        {this.state.showUploadFilesPopup && (
          <UploadFilesPopup
            props={{
              parentNodeId: this.state.selectedNode,
              showUploadFilesPopup: this.state.showUploadFilesPopup,
              filePath: this.state.fileName,
              mainNode: this.state.mainNode,
            }}
            addNewTabulatorRow={this.addNewTabulatorRow}
            unmountMe={this.handleChildUnmount}
          />
        )}
      </Fragment>
    );
  }

  renderExpungementPopup() {
    return (
      <Fragment>
        {this.state.showExpungementPopup && (
          <ExpungementPopup
            props={{
              showExpungementPopup: this.state.showExpungementPopup,
              selectedRow: this.state.fileIdArray,
              client: this.state.client,
              templateName: this.state.selectedTemplate,
            }}
            unmountMe={this.handleChildUnmount}
          />
        )}
      </Fragment>
    );
  }

  renderDeleteFolderPopup() {
    return (
      <Fragment>
        <Popup
          contentStyle={{
            width: "32%",
            borderRadius: "15px",
            padding: "35px",
          }}
          open={this.state.popupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closeopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            <div>
              <h5>Delete Folder - {this.state.fileName}</h5>
              <div>
                <FormGroup>
                  Are you sure to delete the folder and all subfolders and files
                </FormGroup>
                <FormGroup>
                  <Button
                    bsSize="small"
                    bsClass="btn btn-outline-secondary"
                    onClick={this.handleDeleteFolderPopup}
                  >
                    Yes
                  </Button>
                </FormGroup>
              </div>
            </div>
          </div>
        </Popup>
      </Fragment>
    );
  }

  renderDeleteFilePopup() {
    return (
      <Fragment>
        <Popup
          contentStyle={{
            width: "32%",
            borderRadius: "15px",
            padding: "35px",
          }}
          open={this.state.deleteFilePopupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closeopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            <div>
              <h5>Delete File</h5>
              <div>
                <FormGroup>Are you sure to delete the file(s)</FormGroup>
                <FormGroup>
                  <Button
                    bsSize="small"
                    bsClass="btn btn-outline-secondary"
                    onClick={this.handleDeleteFile}
                  >
                    Yes
                  </Button>
                </FormGroup>
              </div>
            </div>
          </div>
        </Popup>
      </Fragment>
    );
  }

  renderToolTipButton = () => {
    return (
      <div className="renderToolTipBrowseButton">
        <div className="emailSelectToolTip">
          {this.props.props.hasManageFiles && (
            <Button
              id="moveButton"
              title="Move File"
              bsSize="xsmall"
              bsClass="btn btn-outline-default"
              onClick={this.handleMoveCopyFilePopup}
            >
              <MoveCopyFileSvg move={true} />
            </Button>
          )}
          {this.props.props.hasManageFiles && (
            <Button
              id="copyButton"
              title="Copy File"
              bsSize="xsmall"
              bsClass="btn btn-outline-default"
              onClick={this.handleMoveCopyFilePopup}
            >
              <MoveCopyFileSvg move={false} />
            </Button>
          )}
          {this.state.client.length > 0 && (
            <Button
              title="Expunge Records"
              bsSize="xsmall"
              bsClass="btn btn-outline-default"
              onClick={this.handleExpungementPopup}
            >
              <ExpungementSvg />
            </Button>
          )}
          {this.props.props.hasDelete && (
            <Button
              title="Delete File"
              bsSize="xsmall"
              bsClass="btn btn-outline-default"
              onClick={this.openDeleteFilePopup}
            >
              <DeleteFileSvg />
            </Button>
          )}
          {this.props.props.hasManageFiles && (
            <Button
              title="Rename Selected Files"
              bsSize="xsmall"
              bsClass="btn btn-outline-default"
              onClick={this.handleRenameFile}
            >
              <RenameFileSvg />
            </Button>
          )}
          {this.props.props.hasSendEmail && (
            <Button
              title="Email Selected"
              bsSize="xsmall"
              bsClass="btn btn-outline-default"
              onClick={this.handlePopup}
            >
              <EmailSvgImage />
            </Button>
          )}
          {this.props.props.hasManageFiles && (
            <Button
              title="Upload Files"
              bsSize="xsmall"
              bsClass="btn btn-outline-default"
              onClick={this.handleUploadFiles}
            >
              <UploadSvgImage />
            </Button>
          )}
        </div>
        <div className="renameFileToolTip">
          <Button
            title="Save"
            bsSize="xsmall"
            bsClass="btn btn-outline-default"
            onClick={this.handleRenameFileSave}
          >
            <RenameFileSaveSvg />
          </Button>
          <Button
            title="Cancel"
            bsSize="xsmall"
            bsClass="btn btn-outline-default"
            onClick={() => this.handleRenameFileCancel(false)}
          >
            <RenameFileCancelSvg />
          </Button>
        </div>
      </div>
    );
  };

  render() {
    let data = this.state.folderArray;
    let fileList = this.state.fileObject;
    let disabledImage = this.state.filePropertiesButton;
    return (
      <Fragment>
        <Header props={this.props.props} history={this.props.history} />
        <Fragment>
          <div className="browseDocumentsync">
            <Row></Row>
            <Split className="wrap" sizes={[25, 75]}>
              <div>
                <Button
                  title="Search Repository"
                  bsClass="btn btn-outline-secondary"
                  className="browseButton"
                  onClick={this.handleSearch}
                >
                  <SearchSvgImage />
                </Button>
                {this.props.props.hasDelete && (
                  <Button
                    title="Delete Folder"
                    bsSize="xsmall"
                    bsClass="btn btn-outline-default"
                    style={{ float: "right" }}
                    onClick={this.openDeleteFolderPopup}
                  >
                    <DeleteFolderSvg />
                  </Button>
                )}
                {this.props.props.hasManageFiles && (
                  <Button
                    title="Add Folder"
                    bsSize="xsmall"
                    bsClass="btn btn-outline-default"
                    style={{ float: "right" }}
                    onClick={this.handleAddFolderPopup}
                  >
                    <AddFolderSvg />
                  </Button>
                )}
                <Tree
                  nodes={data}
                  onSelect={this.onSelect}
                  size="full"
                  theme={"light"}
                  className="comp"
                  iconSet={{
                    node: <BrowserImage />,
                    file: <BrowserImage />,
                  }}
                />
              </div>
              <div>
                <Row>
                  <Col sm={6}>
                    <div style={{ display: "flex" }}>
                      <FormGroup controlId="indexTemplates">
                        <FormControl
                          componentClass="select"
                          required
                          onChange={this.handleIndexTemplateChange}
                          disabled={this.state.disabled}
                        >
                          <option key={-1} value="-1">
                            Select Index Template
                          </option>
                          {this.state.indexTemplateData.map((template, i) => (
                            <option key={i} value={template.tableName}>
                              {template.displayName}
                            </option>
                          ))}
                        </FormControl>
                      </FormGroup>
                      <img
                        src={FilePropertiesImage}
                        alt="FileProperties"
                        style={{
                          width: "40px",
                          height: "37px",
                          cursor: "pointer",
                          opacity: disabledImage ? "1" : "0.5",
                        }}
                        title="Show/Hide Columns"
                        onClick={
                          disabledImage ? this.handleFilePropertiesPopup : null
                        }
                      />
                    </div>
                  </Col>
                  <Col sm={6}>{this.renderToolTipButton()}</Col>
                </Row>
                <Row>
                  <Col
                    sm={12}
                    style={{
                      border: "1px  solid #999",
                      borderRadius: "5px",
                      backgroundColor: "#E6E6E6",
                      flex: "0 0 98%",
                      margin: "auto",
                      display: this.state.disabled ? "none" : "block",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.fileName}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <BrowseGridView
                      className="comp"
                      props={fileList}
                      columns={this.state.columns}
                      editIndex={this.props.props.hasEditIndex}
                      manageRedaction={this.props.props.hasManageRedaction}
                      fileProperties={this.props.props.hasFileProperties}
                      loggedInUser={this.props.props.loggedInUser}
                      hasPIIRole={this.props.props.hasPIIRole}
                      redactionColumn={this.state.redactionColumn}
                      fileSize={this.state.fileSize}
                      pageCount={this.state.pageCount}
                      pageDimensions={this.state.pageDimensions}
                      templateColumnsToShow={this.state.templateColumnsToShow}
                      deletePages={this.props.props.hasDelete}
                      clientId={this.props.props.clientId}
                      onRef={(ref) => (this.child = ref)}
                    />
                  </Col>
                </Row>
              </div>
            </Split>
          </div>
          {this.renderEmailPop()}
          {this.renderExpungementPopup()}
          {this.renderAddFolderPop()}
          {this.renderDeleteFolderPopup()}
          {this.renderDeleteFilePopup()}
          {this.renderUploadFilesPop()}
          {this.renderMoveFilesToFolderPopup()}
          {this.renderAppendPrependFilePopup()}
          {this.renderFilePropertiesPopup()}
        </Fragment>
      </Fragment>
    );
  }
}

export default Browse;
