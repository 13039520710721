import React, { Component, Fragment } from "react";
import Popup from "reactjs-popup";
import Tree from "@naisutech/react-tree";
import BrowserImage from "./browseFolderImage";
import { getInnerNodes, moveOrCopyFiles } from "../../libs/API";
import LoaderButton from "../LoaderButton";
import { ControlLabel, Row, Col } from "react-bootstrap";

class MoveFilesToFolderPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      selectedNode: 0,
      folderArray: [],
      innerNodes: [],
      filePath: "",
      isLoading: false,
      isdisabled: true,
      repoId: 0,
      buttonText: "",
    };
    //console.log("move files to folder popup props", props);
  }

  async componentDidMount() {
    let parentNode = this.props.Data.find(
      (x) => x.id === this.props.selectedNode.id
    );
    let rootNode = this.getRoot(this.props.Data, this.props.selectedNode);
    let newData = null;
    if (parentNode) {
      newData = this.props.Data.filter((x) => x.id === rootNode);
    }
    this.setState({
      popupOpen: this.props.props.showMoveFileToFolderPopup,
      folderArray: newData,
      repoId: parentNode.repoId,
      buttonText: this.props.isCopy === true ? "Copy" : "Move",
    });
  }

  getRoot = (data, selectedNode) => {
    let parentId = selectedNode.parentId;
    if (parentId === null) {
      return selectedNode.id;
    }
    var element = data.find((x) => x.id === parentId);
    if (element && element.parentId !== null) {
      return this.getRoot(data, element);
    }
    return element.id;
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
    this.props.unmountMe();
  };

  async GetInnerNodes(nodeId) {
    await getInnerNodes(nodeId, 1).then((data) => {
      let json = this.state.innerNodes;
      for (let i = 0; i < data.DataTable.length; i++) {
        if (
          json.findIndex((x) => x.nodeId === data.DataTable[i].NodeId) === -1
        ) {
          json.push(data.DataTable[i]);
        }
      }
      let jsonData = this.state.folderArray;
      this.transformInnerChild(nodeId).then((e, json) => {
        for (let i = 0; i < e.length; i++) {
          if (jsonData.findIndex((x) => x.id === e[i].id) === -1) {
            jsonData.push(e[i]);
          }
        }
        this.setState({ folderArray: jsonData });
      });
    });
  }

  generatePath = (nodeId, nodeName) => {
    let data = this.state.folderArray;
    let node = data.find((x) => x.id === nodeId);
    if (node) {
      if (node.parentId != null) {
        return this.generatePath(node.parentId, node.label + "/" + nodeName);
      } else {
        return node.label + "/" + nodeName;
      }
    }
  };

  onSelect = async (selectedNode) => {
    let nodeId = selectedNode.id;
    let node = this.generatePath(nodeId, "");
    let isdisable = false;
    if (
      (node === this.props.mainFilePath && !this.props.isCopy)
    ) {
      isdisable = true;
    } else {
      isdisable = false;
    }
    this.setState({
      selectedNode: nodeId,
      filePath: node,
      isdisabled: isdisable,
    });
    await this.GetInnerNodes(nodeId);
  };

  transformInnerChild = (parentId, json) => {
    return new Promise((y) => {
      let data = this.state.innerNodes;
      let maps = data.map(function (x) {
        return {
          parentId: parentId,
          id: x.NodeId,
          label: x.FolderName,
          items: [],
        };
      });
      return y(maps);
    });
  };

  handleMoveOrCopyFiles = async (e) => {
    this.setState({ isdisabled: true, isLoading: true });
    e.preventDefault();
    let moveFileParameter = {
      fileData: this.props.fileIdArray,
      changeNodeIdTo: this.state.selectedNode,
      repoId: this.state.repoId,
      isCopy: this.props.isCopy,
    };
    await moveOrCopyFiles(moveFileParameter).then(async (x) => {
      console.log(x);
      if (x.status === 200 && !this.props.isCopy) {
        this.props.fileIdArray.forEach((element) => {
          this.props.handleRemoveFilesAfterMove(element.fileId);
        });
      } else if (
        x.status === 200 &&
        this.props.isCopy &&
        this.state.filePath === this.props.mainFilePath
      ) {
        x.data.forEach((element) => {
          let newTabulatorRow = {
            FileName: element.fileName,
            FileId: element.fileId,
            HasAnnotations: element.redaction,
          };
          this.props.addNewTabulatorRow(newTabulatorRow);
        });
      }
      this.setState({
        isdisabled: true,
        isLoading: false,
        buttonText: "Completed",
      });
    });
  };

  render() {
    let text = this.props.isCopy === true ? "Copy" : "Move";
    return (
      <Fragment>
        <Popup
          contentStyle={{
            width: "40%",
            borderRadius: "15px",
            padding: "15px",
          }}
          open={this.state.popupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closePopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            <div>
              <h5>{text} Folder</h5>
              <hr />
              <Row style={{ marginBottom: "2px" }}>
                <Col sm={6} componentClass={ControlLabel}>
                  Select the Folder you want to {text} the file(s):
                </Col>
                <Col sm={6}>
                  <LoaderButton
                    block
                    bsClass="btn btn-outline-secondary"
                    disabled={this.state.isdisabled}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text={`${this.state.buttonText}`}
                    loadingText="Processing File(s)....."
                    onClick={this.handleMoveOrCopyFiles}
                  />
                </Col>
              </Row>
              <Row style={{ margin: "auto" }}>
                <h6>{this.state.filePath}</h6>
                <Tree
                  nodes={this.state.folderArray}
                  onSelect={this.onSelect}
                  size="full"
                  theme={"light"}
                  className="comp"
                  iconSet={{
                    node: <BrowserImage />,
                    file: <BrowserImage />,
                  }}
                />
              </Row>
            </div>
          </div>
        </Popup>
      </Fragment>
    );
  }
}

export default MoveFilesToFolderPopup;
