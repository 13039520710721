import React, { Component, Fragment } from "react";
import {
  FormGroup,
  ControlLabel,
  Row,
  Col,
  FormControl,
  Checkbox,
  HelpBlock,
} from "react-bootstrap";
import Popup from "reactjs-popup";
import LoaderButton from "./LoaderButton";
import {
  fillCliftonExpungmentForm,
  fillMedfordExpungmentForm,
  fillEnglewoodExpungmentForm,
  fillCliftonJuvenileExpungmentForm,
  fillCliftonJuvenileFileReportsExpungmentForm,
  fillGlenrockArrestExpungmentForm,
} from "../libs/API";
import AberdeenExpungement from "../Expungement/AberdeenExpungement";
import DemoExpungement from "../Expungement/DemoExpungement";
import EastamptonArrestExpungement from "../Expungement/EastamptonArrestExpungement";

class ExpungementPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      isLoading: false,
      caseNumber: "",
      caseDate: "",
      expDate: "",
      id: "",
      name: "",
      jacketNumber: "",
      isAppendFile: "",
      isPrependExistingFile: "",
      comments: "",
      characterCount: 0,
    };
    // console.log("Expungement popup props", props);
  }

  async componentDidMount() {
    const selectedRows = this.props.props.selectedRow;
    const templateName = this.props.props.templateName;
    let caseNumber = "";
    let caseDate = "";
    if (selectedRows.length > 0) {
      if (this.props.props.client === "clifton") {
        if (templateName.includes("JUVENILE_FILE_REPORTS")) {
          caseNumber = selectedRows[0].Number || "";
        } else if (templateName.includes("Juvenile")) {
          caseNumber = selectedRows[0]["Case Number"] || "";
        } else {
          caseNumber = selectedRows[0]["Case Number"] || "";
          caseDate = selectedRows[0].Date || "";
        }
      } else if (this.props.props.client === "englewood") {
        caseNumber = selectedRows[0]["Case Number"] || "";
        caseDate = selectedRows[0].Date || "";
      } else if (
        this.props.props.client === "medford" ||
        this.props.props.client === "glenrock"
      ) {
        caseNumber = selectedRows[0]["Case Number"];
        caseDate =
          selectedRows[0]["Date of Incident"] ||
          selectedRows[0]["Date of Arrest"] ||
          selectedRows[0]["Date Of Arrest"];
      }

      this.setState({
        popupOpen: this.props.props.showExpungementPopup,
        caseNumber,
        caseDate,
      });
    }
  }

  closePopup = () => {
    this.setState({ popupOpen: false });
    this.props.unmountMe();
  };

  getFormattedDate = (date) => {
    const year = date.getUTCFullYear();
    let month = (date.getUTCMonth() + 1).toString();
    month = month.length > 1 ? month : "0" + month;
    let day = date.getUTCDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return month + "/" + day + "/" + year;
  };

  handleExpungementSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const client = this.props.props.client;
    const jObject = {
      Case: this.state.caseNumber,
      CaseDate: this.getFormattedDate(new Date(this.state.caseDate)),
      ExpDate: this.getFormattedDate(new Date(this.state.expDate)),
      ID: this.state.id,
      Name: this.state.name,
      Jacket: this.state.jacketNumber,
      Comments: this.state.comments,
    };

    const jArray = [];
    jArray.push(jObject);
    jArray.push({ fileId: this.props.props.selectedRow[0].FileId });
    const isAppendFile =
      this.state.isAppendFile === null || this.state.isAppendFile === ""
        ? false
        : true;
    const isPrependExistingFile =
      this.state.isPrependExistingFile === null ||
      this.state.isPrependExistingFile === ""
        ? false
        : true;
    jArray.push({
      isAppendFile: isAppendFile,
      isPrependExistingFile: isPrependExistingFile,
    });
    if (client === "clifton") {
      fillCliftonExpungmentForm(jArray)
        .then((data) => {
          if (data.status === 200) {
            this.setState({ isLoading: false });
            this.closePopup();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (client === "medford") {
      fillMedfordExpungmentForm(jArray)
        .then((data) => {
          if (data.status === 200) {
            this.setState({ isLoading: false });
            this.closePopup();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleExpungementJuvenileSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const jObject = {
      Case: this.state.caseNumber,
      ExpDate: this.getFormattedDate(new Date(this.state.expDate)),
      ID: this.state.id,
      Name: this.state.name,
      Jacket: this.state.jacketNumber,
      Comments: this.state.comments,
    };

    const jArray = [];
    jArray.push(jObject);
    jArray.push({ fileId: this.props.props.selectedRow[0].FileId });
    const isAppendFile =
      this.state.isAppendFile === null || this.state.isAppendFile === ""
        ? false
        : true;
    const isPrependExistingFile =
      this.state.isPrependExistingFile === null ||
      this.state.isPrependExistingFile === ""
        ? false
        : true;
    jArray.push({
      isAppendFile: isAppendFile,
      isPrependExistingFile: isPrependExistingFile,
    });

    fillCliftonJuvenileExpungmentForm(jArray)
      .then((data) => {
        if (data.status === 200) {
          this.setState({ isLoading: false });
          this.closePopup();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleExpungementJuvenileFileReportsSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const jObject = {
      Number: this.state.caseNumber,
      ExpDate: this.getFormattedDate(new Date(this.state.expDate)),
      ID: this.state.id,
      Name: this.state.name,
      Jacket: this.state.jacketNumber,
      Comments: this.state.comments,
    };

    const jArray = [];
    jArray.push(jObject);
    jArray.push({ fileId: this.props.props.selectedRow[0].FileId });
    const isAppendFile =
      this.state.isAppendFile === null || this.state.isAppendFile === ""
        ? false
        : true;
    const isPrependExistingFile =
      this.state.isPrependExistingFile === null ||
      this.state.isPrependExistingFile === ""
        ? false
        : true;
    jArray.push({
      isAppendFile: isAppendFile,
      isPrependExistingFile: isPrependExistingFile,
    });

    fillCliftonJuvenileFileReportsExpungmentForm(jArray)
      .then((data) => {
        if (data.status === 200) {
          this.setState({ isLoading: false });
          this.closePopup();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleEnglewoodExpungementSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const jObject = {
      ID: this.state.id,
      Jacket: this.state.jacketNumber,
      ExpDate: this.getFormattedDate(new Date(this.state.expDate)),
      Case: this.state.caseNumber,
      CaseDate: this.getFormattedDate(new Date(this.state.caseDate)),
    };

    const jArray = [];
    jArray.push(jObject);
    jArray.push({ fileId: this.props.props.selectedRow[0].FileId });
    fillEnglewoodExpungmentForm(jArray)
      .then((data) => {
        if (data.status === 200) {
          this.setState({ isLoading: false });
          this.closePopup();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleComment = (e) => {
    e.preventDefault();
    let characterCount = e.target.value.length;
    this.setState({
      characterCount: e.target.value.length,
      comments: e.target.value,
    });
    if (characterCount === 400) {
      return;
    }
  };

  handleGlenrockArrestExpungementSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const jObject = {
      Docket: this.state.id,
      Name: this.state.name,
      ExpDate: this.getFormattedDate(new Date(this.state.expDate)),
      Case: this.state.caseNumber,
      CaseDate: this.getFormattedDate(new Date(this.state.caseDate)),
    };

    const jArray = [];
    jArray.push(jObject);
    jArray.push({ fileId: this.props.props.selectedRow[0].FileId });
    const isAppendFile =
      this.state.isAppendFile === null || this.state.isAppendFile === ""
        ? false
        : true;
    const isPrependExistingFile =
      this.state.isPrependExistingFile === null ||
      this.state.isPrependExistingFile === ""
        ? false
        : true;
    // console.log(this.state.isPrependExistingFile, "hello");
    jArray.push({
      isAppendFile: isAppendFile,
      isPrependExistingFile: isPrependExistingFile,
    });

    fillGlenrockArrestExpungmentForm(jArray)
      .then((data) => {
        if (data.status === 200) {
          this.setState({ isLoading: false });
          this.closePopup();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderGlenrockArrestExpungement = () => {
    return (
      <form onSubmit={this.handleGlenrockArrestExpungementSubmit}>
        <FormGroup controlId="caseNumber">
          <Row>
            <Col sm={4} componentClass={ControlLabel}>
              Case #:
            </Col>
            <Col sm={8}>
              <FormControl
                className="input-box"
                type="text"
                required
                readOnly
                value={this.state.caseNumber}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup controlId="caseDate">
          <Row>
            <Col sm={4} componentClass={ControlLabel}>
              Case Date:
            </Col>
            <Col sm={8}>
              <FormControl
                className="input-box"
                type="date"
                readOnly
                value={this.state.caseDate}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup controlId="expDate">
          <Row>
            <Col sm={4} componentClass={ControlLabel}>
              Exp. Date:
            </Col>
            <Col sm={8}>
              <FormControl
                className="input-box"
                type="date"
                onChange={(e) => this.setState({ expDate: e.target.value })}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup controlId="id">
          <Row>
            <Col sm={4} componentClass={ControlLabel}>
              Docket#:
            </Col>
            <Col sm={8}>
              <FormControl
                className="input-box"
                type="text"
                onChange={(e) => this.setState({ id: e.target.value })}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup controlId="name">
          <Row>
            <Col sm={4} componentClass={ControlLabel}>
              Name:
            </Col>
            <Col sm={8}>
              <FormControl
                className="input-box"
                type="text"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm={4}>
              <LoaderButton
                block
                bsClass="btn btn-outline-secondary"
                disabled={this.state.isLoading}
                type="submit"
                isLoading={this.state.isLoading}
                text="Expunge Record"
                loadingText="Expunging Record..."
              />
            </Col>

            <Col sm={8}>
              <Checkbox
                key={1}
                onChange={(e) =>
                  this.setState({ isAppendFile: e.target.value })
                }
              >
                {"   "} Append to Existing Expungement
              </Checkbox>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm={4}></Col>
            <Col sm={8}>
              <Checkbox
                key={1}
                onChange={(e) =>
                  this.setState({
                    isPrependExistingFile: e.target.value,
                  })
                }
              >
                {"   "} Expunge and retain file
              </Checkbox>
            </Col>
          </Row>
        </FormGroup>
      </form>
    );
  };

  render() {
    return (
      <Fragment>
        <Popup
          contentStyle={{
            width: "590px",
            borderRadius: "15px",
            padding: "15px",
            minWidth: "590px !important",
          }}
          open={this.state.popupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closePopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            <h3>Expunge Record</h3>
            <br />
            {this.props.props.client === "englewood" ? (
              <form onSubmit={this.handleEnglewoodExpungementSubmit}>
                <FormGroup controlId="id">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Internal #:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) => this.setState({ id: e.target.value })}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="jacketNumber">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Exp. Docket #:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) =>
                          this.setState({ jacketNumber: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="expDate">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Exp. Date:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="date"
                        onChange={(e) =>
                          this.setState({ expDate: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="caseNumber">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Case #:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        required
                        readOnly
                        value={this.state.caseNumber}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="caseDate">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Case Date:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="date"
                        readOnly
                        value={this.state.caseDate}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col sm={4}>
                      <LoaderButton
                        block
                        bsClass="btn btn-outline-secondary"
                        disabled={this.state.isLoading}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Expunge Record"
                        loadingText="Expunging Record..."
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            ) : this.props.props.templateName.includes(
                "JUVENILE_FILE_REPORTS"
              ) && this.props.props.client === "clifton" ? (
              <form onSubmit={this.handleExpungementJuvenileFileReportsSubmit}>
                <FormGroup controlId="caseNumber">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Number:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        required
                        readOnly
                        value={this.state.caseNumber}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="expDate">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Exp. Date:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="date"
                        onChange={(e) =>
                          this.setState({ expDate: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="id">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      ID#:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) => this.setState({ id: e.target.value })}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="name">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Name:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="jacketNumber">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Jacket#:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) =>
                          this.setState({ jacketNumber: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="comments">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Comments:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        componentClass="textarea"
                        onChange={this.handleComment}
                        maxLength={400}
                      />
                    </Col>
                  </Row>
                  <HelpBlock>
                    Total Characters for Expungement Comment is limited to{" "}
                    {this.state.characterCount}/400.
                  </HelpBlock>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col sm={4}>
                      <LoaderButton
                        block
                        bsClass="btn btn-outline-secondary"
                        disabled={this.state.isLoading}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Expunge Record"
                        loadingText="Expunging Record..."
                      />
                    </Col>
                    <Col sm={8}>
                      <Checkbox
                        key={1}
                        onChange={(e) =>
                          this.setState({ isAppendFile: e.target.value })
                        }
                      >
                        {"   "} Append to Existing Expungement
                      </Checkbox>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}>
                      <Checkbox
                        key={1}
                        onChange={(e) =>
                          this.setState({
                            isPrependExistingFile: e.target.value,
                          })
                        }
                      >
                        {"   "} Expunge and retain file
                      </Checkbox>
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            ) : this.props.props.templateName.includes("Juvenile") &&
              this.props.props.client === "clifton" ? (
              <form onSubmit={this.handleExpungementJuvenileSubmit}>
                <FormGroup controlId="caseNumber">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Case #:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        required
                        readOnly
                        value={this.state.caseNumber}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="expDate">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Exp. Date:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="date"
                        onChange={(e) =>
                          this.setState({ expDate: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="id">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      ID#:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) => this.setState({ id: e.target.value })}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="name">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Name:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="jacketNumber">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Jacket#:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) =>
                          this.setState({ jacketNumber: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="comments">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Comments:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        componentClass="textarea"
                        onChange={this.handleComment}
                        maxLength={400}
                      />
                    </Col>
                  </Row>
                  <HelpBlock>
                    Total Characters for Expungement Comment is limited to{" "}
                    {this.state.characterCount}/400.
                  </HelpBlock>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col sm={4}>
                      <LoaderButton
                        block
                        bsClass="btn btn-outline-secondary"
                        disabled={this.state.isLoading}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Expunge Record"
                        loadingText="Expunging Record..."
                      />
                    </Col>
                    <Col sm={8}>
                      <Checkbox
                        key={1}
                        onChange={(e) =>
                          this.setState({ isAppendFile: e.target.value })
                        }
                      >
                        {"   "} Append to Existing Expungement
                      </Checkbox>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}>
                      <Checkbox
                        key={1}
                        onChange={(e) =>
                          this.setState({
                            isPrependExistingFile: e.target.value,
                          })
                        }
                      >
                        {"   "} Expunge and retain file
                      </Checkbox>
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            ) : this.props.props.client === "glenrock" ? (
              this.renderGlenrockArrestExpungement()
            ) : this.props.props.client === "Aberdeen" ? (
              <AberdeenExpungement
                selectedRow={this.props.props.selectedRow}
                templateName={this.props.props.templateName}
                closeParentPopup={this.closePopup}
                getFormattedDate={this.getFormattedDate}
              />
            ) : this.props.props.client === "Demo" ? (
              <DemoExpungement
                selectedRow={this.props.props.selectedRow}
                closeParentPopup={this.closePopup}
                getFormattedDate={this.getFormattedDate}
              />
            ) : this.props.props.client === "Eastampton" ? (
              <EastamptonArrestExpungement
                selectedRow={this.props.props.selectedRow}
                closeParentPopup={this.closePopup}
                getFormattedDate={this.getFormattedDate}
              />
            ) : (
              <form onSubmit={this.handleExpungementSubmit}>
                <FormGroup controlId="caseNumber">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Case #:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        required
                        readOnly
                        value={this.state.caseNumber}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="caseDate">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Case Date:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="date"
                        readOnly
                        value={this.state.caseDate}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="expDate">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Exp. Date:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="date"
                        onChange={(e) =>
                          this.setState({ expDate: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="id">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      ID#:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) => this.setState({ id: e.target.value })}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="name">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Name:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="jacketNumber">
                  <Row>
                    <Col sm={4} componentClass={ControlLabel}>
                      Jacket#:
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        className="input-box"
                        type="text"
                        onChange={(e) =>
                          this.setState({ jacketNumber: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                {this.props.props.client === "medford" ? (
                  <FormGroup controlId="comments">
                    <Row>
                      <Col sm={4} componentClass={ControlLabel}>
                        Comments:
                      </Col>
                      <Col sm={8}>
                        <FormControl
                          componentClass="textarea"
                          onChange={(e) =>
                            this.setState({ comments: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                ) : (
                  <FormGroup controlId="comments">
                    <Row>
                      <Col sm={4} componentClass={ControlLabel}>
                        Comments:
                      </Col>
                      <Col sm={8}>
                        <FormControl
                          componentClass="textarea"
                          onChange={this.handleComment}
                          maxLength={400}
                        />
                      </Col>
                    </Row>
                    <HelpBlock>
                      Total Characters for Expungement Comment is limited to{" "}
                      {this.state.characterCount}/400.
                    </HelpBlock>
                  </FormGroup>
                )}
                <FormGroup>
                  <Row>
                    <Col sm={4}>
                      <LoaderButton
                        block
                        bsClass="btn btn-outline-secondary"
                        disabled={this.state.isLoading}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Expunge Record"
                        loadingText="Expunging Record..."
                      />
                    </Col>

                    <Col sm={8}>
                      <Checkbox
                        key={1}
                        onChange={(e) =>
                          this.setState({ isAppendFile: e.target.value })
                        }
                      >
                        {"   "} Append to Existing Expungement
                      </Checkbox>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col sm={4}></Col>
                    <Col sm={8}>
                      <Checkbox
                        key={1}
                        onChange={(e) =>
                          this.setState({
                            isPrependExistingFile: e.target.value,
                          })
                        }
                      >
                        {"   "} Expunge and retain file
                      </Checkbox>
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            )}
          </div>
        </Popup>
      </Fragment>
    );
  }
}
export default ExpungementPopup;
