import React, { Component, Fragment } from "react";
import Popup from "reactjs-popup";
import {
  FormControl,
  ControlLabel,
  Row,
  Col,
  FormGroup,
} from "react-bootstrap";
import LoaderButton from "../LoaderButton";
import { addFolder } from "../../libs/API";

class AddFolderPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      isLoading: false,
      folderName: "",
      message: "",
    };
    // console.log("add folder popup props", props);
  }

  async componentDidMount() {
    this.setState({ popupOpen: this.props.props.showAddFolderPopup });
  }

  closePopup = () => {
    this.setState({ popupOpen: false });
    this.props.unmountMe();
  };

  handleAddFolderSubmit = (e) => {
    this.setState({ isLoading: true });
    e.preventDefault();
    let folderParams = [];
    folderParams.push({ nodeId: this.props.props.parentNodeId });
    folderParams.push({ folderName: this.state.folderName });
    addFolder(folderParams).then((data) => {
      if (data > 0) {
        this.setState({ isLoading: false, message: "" });
        let newLeaf = {
          parentId: this.props.props.parentNodeId,
          id: parseInt(data),
          label: this.state.folderName,
          items: [],
        };
        this.props.addFolder(newLeaf);
        this.closePopup();
      } else {
        this.setState({ isLoading: false, message: data });
      }
    });
  };

  render() {
    const rowStyle = {
      marginBottom: "0.4rem",
    };
    return (
      <Fragment>
        <Popup
          contentStyle={{
            width: "32%",
            borderRadius: "15px",
            padding: "15px",
          }}
          open={this.state.popupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closePopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            <div>
              <h5>Add Folder - {this.props.props.filePath}</h5>
              <br />
              <form onSubmit={this.handleAddFolderSubmit}>
                <FormGroup>
                  <div>
                    <Row style={rowStyle}>
                      <Col sm={3} componentClass={ControlLabel}>
                        Folder Name:
                      </Col>
                      <Col sm={9}>
                        <FormControl
                          className="input-box"
                          type="text"
                          required
                          onChange={(e) =>
                            this.setState({ folderName: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <LoaderButton
                    block
                    bsClass="btn btn-outline-secondary"
                    disabled={this.state.isLoading}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Add Folder"
                    loadingText="Adding Folder......"
                  />
                </FormGroup>
                <br />
                <h5 style={{ color: "red" }}>{this.state.message}</h5>
              </form>
            </div>
          </div>
        </Popup>
      </Fragment>
    );
  }
}

export default AddFolderPopup;
