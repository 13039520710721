import React, { Component, Fragment } from "react";
import {
  FormGroup,
  ControlLabel,
  Row,
  Col,
  FormControl,
  Checkbox,
} from "react-bootstrap";
import LoaderButton from "../../src/Component/LoaderButton";
import { fillAberdeenArrestExpungmentForm } from "../libs/API";

class AberdeenExpungement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      caseNumber: "",
      caseDate: "",
      expDate: "",
      id: "",
      name: "",
      isAppendFile: "",
      isPrependExistingFile: "",
      isArrestFiles: false,
      isArrestRecords: false,
      isNonArresetFiles: false,
    };
    // console.log("AberdeenExpungement popup props", props);
  }

  async componentDidMount() {
    const selectedRow = this.props.selectedRow;
    const templateName = this.props.templateName;
    const cleanedTemplateName = templateName.replace(/inx|_|\d+/g, "");
    // console.log(cleanedTemplateName, "cleaned");
    let isArrestFiles = false;
    let isArrestRecords = false;
    let isNonArresetFiles = false;
    let caseNumber,
      caseDate = "";

    if (cleanedTemplateName === "ArrestFiles") {
      isArrestFiles = true;
      caseNumber = selectedRow[0]["Identification Number"] || "";
      caseDate = selectedRow[0]["Date of arrest"] || "";
    } else if (cleanedTemplateName === "ArrestRecords") {
      isArrestRecords = true;
      caseNumber = selectedRow[0]["Incident Number"] || "";
      caseDate = selectedRow[0]["Date Of Arrest"] || "";
    } else {
      caseNumber = selectedRow[0]["Case Number"] || "";
      caseDate = selectedRow[0].Date || "";
      isNonArresetFiles = true;
    }

    this.setState({
      caseNumber,
      caseDate,
      isArrestFiles,
      isArrestRecords,
      isNonArresetFiles,
    });
  }

  handleAberdeenArrestExpungementSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const jObject = {
      Docket: this.state.id,
      Name: this.state.name,
      ExpDate: this.props.getFormattedDate(new Date(this.state.expDate)),
      Case: this.state.caseNumber,
      CaseDate: this.props.getFormattedDate(new Date(this.state.caseDate)),
      isArrestFiles: this.state.isArrestFiles,
      isArrestRecords: this.state.isArrestRecords,
      isNonArresetFiles: this.state.isNonArresetFiles,
    };

    const jArray = [];
    jArray.push(jObject);
    jArray.push({ fileId: this.props.selectedRow[0].FileId });
    const isAppendFile =
      this.state.isAppendFile === null || this.state.isAppendFile === ""
        ? false
        : true;
    const isPrependExistingFile =
      this.state.isPrependExistingFile === null ||
      this.state.isPrependExistingFile === ""
        ? false
        : true;
    // console.log(this.state.isPrependExistingFile, "hello");
    jArray.push({
      isAppendFile: isAppendFile,
      isPrependExistingFile: isPrependExistingFile,
    });

    fillAberdeenArrestExpungmentForm(jArray)
      .then((data) => {
        if (data.status === 200) {
          this.setState({ isLoading: false });
          this.props.closeParentPopup();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleAberdeenArrestExpungementSubmit}>
          <FormGroup controlId="caseNumber">
            <Row>
              <Col sm={4} componentClass={ControlLabel}>
                Case #:
              </Col>
              <Col sm={8}>
                <FormControl
                  className="input-box"
                  type="text"
                  required
                  readOnly
                  value={this.state.caseNumber}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup controlId="caseDate">
            <Row>
              <Col sm={4} componentClass={ControlLabel}>
                Case Date:
              </Col>
              <Col sm={8}>
                <FormControl
                  className="input-box"
                  type="date"
                  readOnly
                  value={this.state.caseDate}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup controlId="expDate">
            <Row>
              <Col sm={4} componentClass={ControlLabel}>
                Exp. Date:
              </Col>
              <Col sm={8}>
                <FormControl
                  className="input-box"
                  type="date"
                  onChange={(e) => this.setState({ expDate: e.target.value })}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup controlId="id">
            <Row>
              <Col sm={4} componentClass={ControlLabel}>
                Docket#:
              </Col>
              <Col sm={8}>
                <FormControl
                  className="input-box"
                  type="text"
                  onChange={(e) => this.setState({ id: e.target.value })}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup controlId="name">
            <Row>
              <Col sm={4} componentClass={ControlLabel}>
                Name:
              </Col>
              <Col sm={8}>
                <FormControl
                  className="input-box"
                  type="text"
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm={4}>
                <LoaderButton
                  block
                  bsClass="btn btn-outline-secondary"
                  disabled={this.state.isLoading}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Expunge Record"
                  loadingText="Expunging Record..."
                />
              </Col>

              <Col sm={8}>
                <Checkbox
                  key={1}
                  onChange={(e) =>
                    this.setState({ isAppendFile: e.target.value })
                  }
                >
                  {"   "} Append to Existing Expungement
                </Checkbox>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm={4}></Col>
              <Col sm={8}>
                <Checkbox
                  key={1}
                  onChange={(e) =>
                    this.setState({
                      isPrependExistingFile: e.target.value,
                    })
                  }
                >
                  {"   "} Expunge and retain file
                </Checkbox>
              </Col>
            </Row>
          </FormGroup>
        </form>
      </Fragment>
    );
  }
}

export default AberdeenExpungement;
