export const config = {
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Ay1jNpzGc",
    APP_CLIENT_ID: "1kkr46pqb0m7j8lmpfa0p0lo87",
    IDENTITY_POOL_ID: "us-east-1:8509f566-818c-478a-bcb6-152bd6ad9f5c",
  },
  FoveonicsAPI: "https://api.documentsync.com",
  ImageViewer: "https://viewer.documentsync.com",
  Geolocation: "https://api.ipify.org/?format=json",
};

export default config;
