import React, { Component } from "react";
import Popup from "reactjs-popup";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import { FormControl, ControlLabel, Button, Row, Col } from "react-bootstrap";
import {
  getListValue,
  getListValueField,
  updateControlListData,
  deleteListField,
  addFieldListData,
} from "../../libs/API";

class RenderFieldPopup extends Component {
  gridListValueRef = null;
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      selectListValue: [],
      listValues: [],
      inputFieldName: "",
      canBeLoaded: false,
      selectedField: "",
    };
    this.closePopup = this.closePopup.bind(this);
  }

  async componentDidMount() {
    this.setState({ popupOpen: this.props.props.showFieldPopup });
    await this.getControlledList();
  }

  closePopup() {
    this.setState({ popupOpen: false });
    this.props.unmountMe();
  }

  getControlledList = async () => {
    const tableName = this.props.props.tableName;
    getListValue(tableName).then((data) => {
      this.setState({ selectListValue: data });
    });
  };

  handleOnChange = async (e) => {
    if (e.target.value.length > 0) {
      this.setState({ selectedField: e.target.value });
      await this.getListValuesForTemplateField(e.target.value);
    } else {
      this.setState({ canBeLoaded: false });
    }
  };

  getListValuesForTemplateField = async (fieldName) => {
    const tableName = this.props.props.tableName;
    getListValueField(tableName, fieldName).then((data) => {
      this.setState({ listValues: data, canBeLoaded: true });
    });
  };

  updateDeleteFields = async (e, cell) => {
    e.preventDefault();
    if (e.target.innerText === "Update") {
      let rowData = cell.getRow().getData();
      updateControlListData(rowData).then((data) => {
        if (data.status === 200) {
          alert("Updated");
        }
      });
    } else if (e.target.innerText === "Delete") {
      let controlledListId = cell.getRow().getData().autokey;
      deleteListField(controlledListId).then((data) => {
        if (data.status === 200) {
          cell.getRow().delete();
          alert("Deleted");
        }
      });
    }
  };

  addListField = async (e) => {
    e.preventDefault();
    let addParams = {
      tableName: this.props.props.tableName,
      valueEntry: this.state.inputFieldName,
      fieldName: this.state.selectedField,
    };
    addFieldListData(addParams).then((data) => {
      let newFeild = {
        valueEntry: addParams.valueEntry,
        autokey: data,
      };
      this.gridListValueRef.table.addRow(newFeild, false);
    });
  };

  renderListContent() {
    let addUpdateandDeleteButton = function () {
      let button =
        "<div><button type='button'class='btn btn-outline-secondary btn-sm updatedeletebtnindextemplate' >Update</button> <button type='button'class='btn btn-outline-secondary btn-sm updatedeletebtnindextemplate' >Delete</button></div>";
      return button;
    };
    return (
      <div>
        <ControlLabel className="indexTemplatemargin">Edit List</ControlLabel>
        <Row className="marginforpopupField">
          <Col componentClass={ControlLabel} sm={5}>
            Controlled Lists for this Template:
          </Col>
          <Col sm={5}>
            <FormControl componentClass="select" onChange={this.handleOnChange}>
              <option key={-1} value="">
                Select One--
              </option>
              {this.state.selectListValue.map((x) => (
                <option key={x.dataType} value={x.inputName}>
                  {x.inputName}
                </option>
              ))}
            </FormControl>
          </Col>
          <Col sm={2}></Col>
        </Row>
        {this.state.canBeLoaded ? (
          <div>
            <form onSubmit={this.addListField}>
              <Row className="marginforpopupField">
                <Col sm={5}>
                  <FormControl
                    className="input-box"
                    type="input"
                    required
                    onChange={(e) =>
                      this.setState({ inputFieldName: e.target.value })
                    }
                  />
                </Col>
                <Col sm={3}>
                  <Button
                    bsClass="btn btn-outline-secondary"
                    bsSize="small"
                    type="submit"
                  >
                    Add Field
                  </Button>
                </Col>
                <Col sm={4}></Col>
              </Row>
            </form>
            <Row className="marginforpopupField">
              <Col sm={12}>
                <ReactTabulator
                  id="ListTable"
                  columns={[
                    {
                      title: "",
                      headerSort: false,
                      field: "valueEntry",
                      editor: "input",
                    },
                    {
                      title: "",
                      headerSort: false,
                      formatter: addUpdateandDeleteButton,
                      hozAlign: "center",
                      cellClick: (e, cell) => {
                        this.updateDeleteFields(e, cell);
                      },
                    },
                  ]}
                  data={this.state.listValues}
                  ref={(ref) => (this.gridListValueRef = ref)}
                  options={{
                    pagination: "local",
                    paginationSize: 5,
                    placeholder: "No Data Available",
                  }}
                />
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        <Popup
          contentStyle={{
            width: "50%",
            borderRadius: "15px",
            padding: "15px",
          }}
          open={this.state.popupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closePopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            <div>{this.renderListContent()}</div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default RenderFieldPopup;
