import React from "react";
import { BrowserRouter } from "react-router-dom";
import RoutePage from "./Component/Route/route";
import { role } from "./Component/enum";
import { Auth } from "aws-amplify";
import { getUserDetailsByEmail } from "./libs/API";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      hasAuthenticated: false,
      isAuthenticating: true,
      loggedInUser: "",
      hasManageMetadataTemplate: false,
      hasManageRepositories: false,
      hasEditIndex: false,
      hasManageRedaction: false,
      hasManageUsers: false,
      hasTextSearch: false,
      hasManageFiles: false,
      hasDelete: false,
      hasCliftonWorkflow: false,
      hasEnglewoodWorkflow: false,
      hasFoveonicsAdmin: false,
      hasSendEmail: false,
      hasMedfordWorkflow: false,
      hasRestrictAccessByIP: false,
      hasDownloadIndex: false,
      hasFileProperties: false,
      hasGlenrockWorkflow: false,
      hasAberdeenWorkflow: false,
      userIpAddress: "",
      userId: "",
      hasOcrRole: false,
      hasPIIRole: false,
      hasDemoExpungementWorkflow: false,
      hasEastamptonExpungementWorkflow: false,
      clientId: 0,
      groups: null,
    };
    this.userHasAuthenticated = this.userHasAuthenticated.bind(this);
  }

  async componentDidMount() {
    try {
      await this.setUserPermissions();
    } catch (e) {
      if (e !== "No current user") {
        console.log(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = (passedAuthenticated) => {
    this.setState({ hasAuthenticated: passedAuthenticated });
  };

  setUserPermissions = async (ip) => {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    // const groups = user.signInUserSession.idToken.payload["cognito:groups"];
    //sql call dsuserroles
    const userDetails = await getUserDetailsByEmail(user.attributes["email"]);
    const groups = userDetails.map((user) => user.name);
    const clientId = userDetails && userDetails[0].clientId;
    this.setState({
      loggedInUser: user.attributes["email"],
      userId: user.username,
      userIpAddress: ip,
      clientId: clientId,
      groups: groups,
      hasManageMetadataTemplate:
        groups && groups.includes(role.documentSync_Manage_Metadata_Template),
      hasManageRepositories:
        groups && groups.includes(role.documentSync_Manage_Repositories),
      hasEditIndex: groups && groups.includes(role.documentSync_Edit_Index),
      hasManageRedaction:
        groups && groups.includes(role.documentSync_Manage_Redactions),
      hasManageUsers: groups && groups.includes(role.documentSync_Manage_Users),
      hasTextSearch: groups && groups.includes(role.documentSync_Text_Search),
      hasManageFiles: groups && groups.includes(role.documentSync_Manage_Files),
      hasDelete: groups && groups.includes(role.documentSync_Delete),
      hasCliftonWorkflow:
        groups && groups.includes(role.documentSync_CliftonExpungementWorkflow),
      hasFoveonicsAdmin: groups && groups.includes(role.foveonicsAdmin),
      hasSendEmail: groups && groups.includes(role.documentSync_Email),
      hasMedfordWorkflow:
        groups && groups.includes(role.documentSync_MedfordExpungementWorkflow),
      hasGlenrockWorkflow:
        groups &&
        groups.includes(role.documentSync_GlenrockExpungementWorkflow),
      hasEnglewoodWorkflow:
        groups &&
        groups.includes(role.documentSync_EnglewoodExpungementWorkflow),
      hasAberdeenWorkflow:
        groups &&
        groups.includes(role.documentSync_AberdeenExpungementWorkflow),
      hasRestrictAccessByIP:
        groups && groups.includes(role.documentSync_RestrictAccessByIP),
      hasDownloadIndex:
        groups && groups.includes(role.documentSync_DownloadIndex),
      hasFileProperties:
        groups && groups.includes(role.documentSync_FileProperties),
      hasOcrRole: groups && groups.includes(role.documentSync_OCR),
      hasPIIRole: groups && groups.includes(role.documentSync_PII),
      hasDemoExpungementWorkflow:
        groups && groups.includes(role.documentSync_DemoExpungementWorkflow),
      hasEastamptonExpungementWorkflow:
        groups &&
        groups.includes(role.documentSync_EastamptonExpungementWorkflow),
    });
    this.userHasAuthenticated(true);
  };

  render() {
    const childProps = {
      hasAuthenticated: this.state.hasAuthenticated,
      loggedInUser: this.state.loggedInUser,
      hasManageMetadataTemplate: this.state.hasManageMetadataTemplate,
      hasManageRepositories: this.state.hasManageRepositories,
      hasEditIndex: this.state.hasEditIndex,
      hasManageRedaction: this.state.hasManageRedaction,
      hasManageUsers: this.state.hasManageUsers,
      hasManageFiles: this.state.hasManageFiles,
      hasTextSearch: this.state.hasTextSearch,
      hasDelete: this.state.hasDelete,
      hasCliftonWorkflow: this.state.hasCliftonWorkflow,
      hasEnglewoodWorkflow: this.state.hasEnglewoodWorkflow,
      hasFoveonicsAdmin: this.state.hasFoveonicsAdmin,
      hasSendEmail: this.state.hasSendEmail,
      hasMedfordWorkflow: this.state.hasMedfordWorkflow,
      hasGlenrockWorkflow: this.state.hasGlenrockWorkflow,
      hasRestrictAccessByIP: this.state.hasRestrictAccessByIP,
      hasDownloadIndex: this.state.hasDownloadIndex,
      hasFileProperties: this.state.hasFileProperties,
      userIpAddress: this.state.userIpAddress,
      userId: this.state.userId,
      hasOcrRole: this.state.hasOcrRole,
      hasPIIRole: this.state.hasPIIRole,
      clientId: this.state.clientId,
      hasAberdeenWorkflow: this.state.hasAberdeenWorkflow,
      hasDemoExpungementWorkflow: this.state.hasDemoExpungementWorkflow,
      hasEastamptonExpungementWorkflow:
        this.state.hasEastamptonExpungementWorkflow,
      setUserPermissions: this.setUserPermissions,
      userHasAuthenticated: this.userHasAuthenticated,
      groups: this.state.groups,
    };
    //console.log("childProps", childProps);
    return (
      !this.state.isAuthenticating && (
        <BrowserRouter>
          <div className="App"></div>
          <RoutePage props={childProps} />
        </BrowserRouter>
      )
    );
  }
}

export default App;
